<br>

<div class="page-wrapper">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">

            <div class="default_class">
                <div class="content-wrapper p-0">

                    <div class="container-fluid">

                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0">Attendance Details </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="form-group">
                                            <div class="row">
                                                <div class="col-md-3">
                                                    <label for="sel1">Course Name : </label>
                                                    <select [(ngModel)]='courceId' id="sel1" (change)="courceChange()"
                                                        class="form-control form-control-sm shadow-sm rounded-lg">
                                                        <option value="" [selected]="true">select</option>
                                                        <option *ngFor="let course of cources"
                                                            [value]='course.COURSE_ID'>
                                                            {{course.COURSE_NAME}}
                                                        </option>

                                                    </select>
                                                </div>
                                                <div class="col-md-3">
                                                    <label for="sel1">Course Schedule :</label>
                                                    <select [(ngModel)]='schedulId ' id="sel1"
                                                        class="form-control form-control-sm shadow-sm rounded-lg"
                                                        (change)="schedulChange()">
                                                        <option value="" [selected]="true">select</option>
                                                        <option *ngFor="let schedul of scheduls"
                                                            [value]='schedul.COURSESHD_ID'>
                                                            {{schedul.COURSESHD_NAME}}</option>

                                                    </select>


                                                </div>
                                                <div class="col-md-3">
                                                    <label for="sel1">Sessions :</label>
                                                    <select [(ngModel)]='sessionId ' id="sel1"
                                                        class="form-control form-control-sm shadow-sm rounded-lg"
                                                        (change)="sessionChange()">
                                                        <option value="" [selected]="true">select</option>
                                                        <option *ngFor="let session of sessions" [value]='session.Date'>
                                                            {{session.Session}}</option>

                                                    </select>


                                                </div>
                                            </div>


                                        </div>

                                        <div *ngIf="table.length" class="pt-4">
                                            <table id="patreggraph" datatable
                                                class=" table-striped table-bordered mt-2 pt-2 table-sm small "
                                                style="width:100%">
                                                <thead class="top" style="background-color: #0d9bc4; color:#ffffff;">
                                                    <tr>
                                                        <th>Name</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of table">
                                                        <td>{{item.USER_FIRSTNAME}}</td>
                                                        <td><input type="checkbox" [(ngModel)]='item.ATTENDANCE_STATUS'>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="text-center pt-4" *ngIf="!table.length">No Records to display</div>
                                    </div>

                                    <div class="text-center">
                                        <button type="button" class="btn btn-sm  btn-save" (click)="submit()">Submit</button>
                                    </div>
                                </div>
                              
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>