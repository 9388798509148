import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from 'src/app/services/common.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-company-registration',
  templateUrl: './company-registration.component.html',
  styleUrls: ['./company-registration.component.css']
})
export class CompanyRegistrationComponent implements OnInit {
  pageTitle: string = "Organisation";
  companyRegistrationForm: FormGroup;
  submitted: boolean = false;
  isMeeting: Boolean = false;
  isDesabled: Boolean = false;
  @Input() Data: any;
  @Output() CompanyRegistrationEvent: EventEmitter<boolean> = new EventEmitter();
  isDomainExists: boolean = false;

  constructor(private fb: FormBuilder, public CommonService: CommonService, private route: Router, private toastr: ToastrService) { }

  ngOnInit(): void {

    document.getElementById('btnOpenModel').click();
    this.formInit();
  }

  formInit() {
    this.companyRegistrationForm = this.fb.group({
      company_name: [this.Data?.Company_Name ?? '', Validators.required],
      registration_no: ['', Validators.required],
      contact: [this.Data?.MobileNo ?? '', Validators.required],
      email: [this.Data?.emailid ?? '', Validators.required],
      address: ['', Validators.required],
      subdomainname: ['', Validators.required],

    });
  }


  close() {
    this.CompanyRegistrationEvent.emit(false);

  }


  get f() { return this.companyRegistrationForm.controls; }

  onSubmit(form: FormGroup) {
    this.submitted = true;
    if (this.companyRegistrationForm.invalid) {
      this.toastr.warning("Please enter all mandetory field", this.pageTitle);
      return;
    }

    if (this.isDomainExists) {
      this.toastr.error('Subdomain Already Exist', this.pageTitle);
      return;
    }

    if (!this.isDesabled) {
      return this.toastr.warning("Please read and accept the Terms and Condition", this.pageTitle);
    }

    // const saveData = JSON.parse(JSON.stringify(this.companyRegistrationForm.getRawValue()));
    const saveData = { ...this.companyRegistrationForm.getRawValue() };
    console.log(saveData);
    this.CompanyRegistrationEvent.emit(saveData);
    document.getElementById("btnClose").click();
  }


  conditionCheck(event: any) {

    let val = event.target.checked;
    if (val) {
      this.isDesabled = true;
    } else {
      this.isDesabled = false;
    }
  }

  navToTerms() {
    this.route.navigate(['/eRP/terms-conditions']).then(() => {
      window.location.reload();
    });
  }

  navToPrivacy() {
    this.route.navigate(['/eRP/privacy-policy']).then(() => {
      window.location.reload();
    });
  }

  domainChange() {
    const subdomainname = this.companyRegistrationForm.get('subdomainname').value;
    if (subdomainname) {
      this.isExistDomain(subdomainname);
    }
  }

  isExistDomain(subdomain_name: string) {
    this.isDomainExists = false;
    this.CommonService.getCall(`account/IsSubDomainExists/${subdomain_name}`).subscribe((res: any) => {
      this.isDomainExists = res.data;
      if (this.isDomainExists)
        this.toastr.error('Subdomain Already Exist', this.pageTitle);
    });
  }

}
