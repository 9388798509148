<!-- <li><a class="has-arrow" [routerLink]="['/student-home']"><i class="bx bx-right-arrow-alt"></i>Course </a></li> -->
<!-- <li metis-menu class="mt-0"><a class="has-arrow" [routerLink]="['/home/userRegistration']"><div class="menu-title">My Profile</div> </a></li>
<li metis-menu class="mt-0"><a class="has-arrow" [routerLink]="['/student-home']"><div class="menu-title">Course</div> </a></li>
<li metis-menu class="mt-0"><a class="has-arrow" [routerLink]="['/home/my-courses']"><div class="menu-title">My Courses</div> </a></li>
<li metis-menu class="mt-0"><a class="has-arrow" [routerLink]="['/home/my-tutors']"><div class="menu-title">My Tutors</div> </a></li>
<li metis-menu class="mt-0"><a class="has-arrow" [routerLink]="['/home/my-payments']"><div class="menu-title">My Payments</div> </a></li> -->

<!-- <li><a class="has-arrow" [routerLink]="['/home/myresults']"><i class="bx bx-right-arrow-alt"></i>My Results</a></li> -->
<!-- <li metis-menu class="mt-0"><a class="has-arrow" [routerLink]="['/home/submitassignment']"><div class="menu-title">Submit
  Assignment</div> </a> </li>

    <li metis-menu class="mt-0 ">
        <a href="#conference`" class="has-arrow">
          <div class="menu-title">Communication</div>
        </a>
        <ul class="parent-icon" id="conference">
          <li><a class="has-arrow" [routerLink]="['/home/meetings']"><i class="bx bx-right-arrow-alt" ></i>Recording Storage</a></li>
          <li><a class="has-arrow" [routerLink]="['/home/eventRequest']"><i class="bx bx-right-arrow-alt" ></i>Event Request</a></li>
          <li><a class="has-arrow" [routerLink]="['/home/joinconference']"><i class="bx bx-right-arrow-alt" ></i>Join Conference</a></li>
          </ul>
        
    </li>  -->


<div class="wrapper" style="height: auto; overflow: hidden">
  <!--sidebar wrapper -->
  <div class="sidebar-wrapper" data-simplebar="true">

    <div class="sidebar-menu-wrapper">
      <div class="sidebar-header">
        <div>
          <img id="homepageimage_path" src="../../assets/img/samvaad_tutor_logo.png" class="img-fluid" alt="logo icon">
        </div>
        <!-- <div>
                <h4 class="logo-text">LMS</h4>
              </div> -->
        <!-- <div class="toggle-icon ms-auto"><i class='bx bx-arrow-to-left'></i>
              </div> -->
      </div>
      <div class="wrapper" style="margin-top: 9.6vh">
        <ul class="style-1 shadow nav-active1" style="display:block;">

          <li class="parent"><a [routerLink]="['/HOME/userRegistration']" class=""><span class="icon"><i
                  class="fas fa-user"></i></span>&nbsp;&nbsp;<span class="text">My Profile</span></a>
          </li>

          <li class="parent"><a [routerLink]="['/student-home']" class=""><span class="icon"><i
                  class="fas fa-book"></i></span>&nbsp;&nbsp;<span class="text">Course</span></a>
          </li>

          <li class="parent"><a [routerLink]="['/HOME/my-courses']" class=""><span class="icon"><i
                  class="fas fa-file-signature"></i></span>&nbsp;&nbsp;<span class="text">My Courses</span></a>
          </li>
          <li class="parent"><a [routerLink]="['/HOME/my-tutors']" class=""><span class="icon"><i
                  class="fas fa-chalkboard-teacher"></i></span>&nbsp;&nbsp;<span class="text">My Tutors</span></a>
          </li>
          <li class="parent"><a [routerLink]="['/HOME/my-payments']" class=""><span class="icon"><i
                  class="fas fa-file-invoice-dollar"></i></span>&nbsp;&nbsp;<span class="text">My Payments</span></a>
          </li>

          <li class="parent"><a [routerLink]="['/HOME/myresults']" class=""><span class="icon"><i
                  class="fas fa-pen"></i></span>&nbsp;&nbsp;<span class="text">My Results</span></a>
          </li>

          <li class="parent"><a [routerLink]="['/HOME/submitassignment']" class=""><span class="icon"><i
                  class="fas fa-pen"></i></span>&nbsp;&nbsp;<span class="text">Submit Assignment</span></a>
          </li>
          <li class="parent"><a [routerLink]="['/HOME/learning-resources']" class=""><span class="icon"><i
                  class="fas fa-chalkboard-teacher"></i></span>&nbsp;&nbsp;<span class="text"> Learning Resources </span></a>
          </li>



          <!-- </ul>
      </div> -->



          <!-- <li class="parent"><a class="has-arrow"><span class="icon"><i
              class="fas fa-satellite-dish"></i></span>&nbsp;&nbsp;<span class="text">Conference</span></a>
        <div class="wrapper">
          <ul>
            <li><a [routerLink]="['/home/meetings']">Recording Storage</a></li>
            <li><a [routerLink]="['/home/eventRequest']">Event Request</a></li>
            <li><a [routerLink]="['/home/joinconference']">Join Conference</a></li>
          </ul>
        </div>
      </li>
     -->


        </ul>
      </div>

    </div>
  </div>
</div>