<div class="page-wrapper">
    <div class="page-content pt-4 pe-3 ps-3">
        <!-- <header>
            <div class="topbar d-flex align-items-center">
                <nav class="navbar navbar-expand ps-0">
                    <nav aria-label="breadcrumb" class="d-none d-sm-none d-md-block">
                       
                        <ol class="breadcrumb mb-0 p-0 d-none">
                            <li class="breadcrumb-item"><a href="#"><i class="bx bx-home-alt"></i></a>
                            </li>
                            <li class="breadcrumb-item active" style="font-size: 15px;" aria-current="page">Dashboard
                            </li>
                        </ol>
                    </nav>
                    <div class="mobile-toggle-menu"><i class='bx bx-menu'></i>
                    </div>

                    <div class="search-bar flex-grow-1">
                        <div class="position-relative search-bar-box">
                            <input type="text" class="form-control search-control" placeholder="Type to search...">
                            <span class="position-absolute top-50 search-show translate-middle-y"><i
                                    class='bx bx-search'></i></span>
                          
                        </div>
                    </div>
                    <div class="top-menu ms-auto">
                        <ul class="navbar-nav align-items-center">
                          
                            <li class="nav-item dropdown dropdown-large ">
                                <a class="nav-link dropdown-toggle dropdown-toggle-nocaret position-relative" href="#"
                                    role="button" data-bs-toggle="dropdown" aria-expanded="false"> <span
                                        class="alert-count">7</span>
                                    <i class='bx bx-bell'></i>
                                </a>
                                <div class="dropdown-menu dropdown-menu-end">
                                    <a href="javascript:;">
                                        <div class="msg-header">
                                            <p class="msg-header-title">Notifications</p>
                                            <p class="msg-header-clear ms-auto">Marks all as read</p>
                                        </div>
                                    </a>
                                    <div class="header-notifications-list">
                                        <a class="dropdown-item" href="javascript:;">
                                            <div class="d-flex align-items-center">
                                                <div class="notify bg-light-primary text-primary"><i
                                                        class="bx bx-group"></i>
                                                </div>
                                                <div class="flex-grow-1">
                                                    <h6 class="msg-name">New Customers<span
                                                            class="msg-time float-end">14 Sec
                                                            ago</span></h6>
                                                    <p class="msg-info">5 new user registered</p>
                                                </div>
                                            </div>
                                        </a>
                                        <a class="dropdown-item" href="javascript:;">
                                            <div class="d-flex align-items-center">
                                                <div class="notify bg-light-danger text-danger"><i
                                                        class="bx bx-cart-alt"></i>
                                                </div>
                                                <div class="flex-grow-1">
                                                    <h6 class="msg-name">New Orders <span class="msg-time float-end">2
                                                            min
                                                            ago</span></h6>
                                                    <p class="msg-info">You have recived new orders</p>
                                                </div>
                                            </div>
                                        </a>
                                        <a class="dropdown-item" href="javascript:;">
                                            <div class="d-flex align-items-center">
                                                <div class="notify bg-light-success text-success"><i
                                                        class="bx bx-file"></i>
                                                </div>
                                                <div class="flex-grow-1">
                                                    <h6 class="msg-name">24 PDF File<span class="msg-time float-end">19
                                                            min
                                                            ago</span></h6>
                                                    <p class="msg-info">The pdf files generated</p>
                                                </div>
                                            </div>
                                        </a>
                                        <a class="dropdown-item" href="javascript:;">
                                            <div class="d-flex align-items-center">
                                                <div class="notify bg-light-warning text-warning"><i
                                                        class="bx bx-send"></i>
                                                </div>
                                                <div class="flex-grow-1">
                                                    <h6 class="msg-name">Time Response <span
                                                            class="msg-time float-end">28 min
                                                            ago</span></h6>
                                                    <p class="msg-info">5.1 min avarage time response</p>
                                                </div>
                                            </div>
                                        </a>
                                        <a class="dropdown-item" href="javascript:;">
                                            <div class="d-flex align-items-center">
                                                <div class="notify bg-light-info text-info"><i
                                                        class="bx bx-home-circle"></i>
                                                </div>
                                                <div class="flex-grow-1">
                                                    <h6 class="msg-name">New Product Approved <span
                                                            class="msg-time float-end">2 hrs
                                                            ago</span></h6>
                                                    <p class="msg-info">Your new product has approved</p>
                                                </div>
                                            </div>
                                        </a>
                                        <a class="dropdown-item" href="javascript:;">
                                            <div class="d-flex align-items-center">
                                                <div class="notify bg-light-danger text-danger"><i
                                                        class="bx bx-message-detail"></i>
                                                </div>
                                                <div class="flex-grow-1">
                                                    <h6 class="msg-name">New Comments <span class="msg-time float-end">4
                                                            hrs
                                                            ago</span></h6>
                                                    <p class="msg-info">New customer comments recived</p>
                                                </div>
                                            </div>
                                        </a>
                                        <a class="dropdown-item" href="javascript:;">
                                            <div class="d-flex align-items-center">
                                                <div class="notify bg-light-success text-success"><i
                                                        class='bx bx-check-square'></i>
                                                </div>
                                                <div class="flex-grow-1">
                                                    <h6 class="msg-name">Your item is shipped <span
                                                            class="msg-time float-end">5 hrs
                                                            ago</span></h6>
                                                    <p class="msg-info">Successfully shipped your item</p>
                                                </div>
                                            </div>
                                        </a>
                                        <a class="dropdown-item" href="javascript:;">
                                            <div class="d-flex align-items-center">
                                                <div class="notify bg-light-primary text-primary"><i
                                                        class='bx bx-user-pin'></i>
                                                </div>
                                                <div class="flex-grow-1">
                                                    <h6 class="msg-name">New 24 authors<span
                                                            class="msg-time float-end">1 day
                                                            ago</span></h6>
                                                    <p class="msg-info">24 new authors joined last week</p>
                                                </div>
                                            </div>
                                        </a>
                                        <a class="dropdown-item" href="javascript:;">
                                            <div class="d-flex align-items-center">
                                                <div class="notify bg-light-warning text-warning"><i
                                                        class='bx bx-door-open'></i>
                                                </div>
                                                <div class="flex-grow-1">
                                                    <h6 class="msg-name">Defense Alerts <span
                                                            class="msg-time float-end">2 weeks
                                                            ago</span></h6>
                                                    <p class="msg-info">45% less alerts last 4 weeks</p>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                    <a href="javascript:;">
                                        <div class="text-center msg-footer">View All Notifications</div>
                                    </a>
                                </div>
                            </li>
                            <li class="nav-item dropdown dropdown-large d-none">
                                <a class="nav-link dropdown-toggle dropdown-toggle-nocaret position-relative" href="#"
                                    role="button" data-bs-toggle="dropdown" aria-expanded="false"> <span
                                        class="alert-count">8</span>
                                    <i class='bx bx-comment'></i>
                                </a>
                                <div class="dropdown-menu dropdown-menu-end">
                                    <a href="javascript:;">
                                        <div class="msg-header">
                                            <p class="msg-header-title">Messages</p>
                                            <p class="msg-header-clear ms-auto">Marks all as read</p>
                                        </div>
                                    </a>
                                    <div class="header-message-list">
                                        <a class="dropdown-item" href="javascript:;">
                                            <div class="d-flex align-items-center">
                                                <div class="user-online">
                                                    <img src="https://via.placeholder.com/110x110" class="msg-avatar"
                                                        alt="user avatar">
                                                </div>
                                                <div class="flex-grow-1">
                                                    <h6 class="msg-name">Daisy Anderson <span
                                                            class="msg-time float-end">5 sec
                                                            ago</span></h6>
                                                    <p class="msg-info">The standard chunk of lorem</p>
                                                </div>
                                            </div>
                                        </a>
                                        <a class="dropdown-item" href="javascript:;">
                                            <div class="d-flex align-items-center">
                                                <div class="user-online">
                                                    <img src="https://via.placeholder.com/110x110" class="msg-avatar"
                                                        alt="user avatar">
                                                </div>
                                                <div class="flex-grow-1">
                                                    <h6 class="msg-name">Althea Cabardo <span
                                                            class="msg-time float-end">14
                                                            sec ago</span></h6>
                                                    <p class="msg-info">Many desktop publishing packages</p>
                                                </div>
                                            </div>
                                        </a>
                                        <a class="dropdown-item" href="javascript:;">
                                            <div class="d-flex align-items-center">
                                                <div class="user-online">
                                                    <img src="https://via.placeholder.com/110x110" class="msg-avatar"
                                                        alt="user avatar">
                                                </div>
                                                <div class="flex-grow-1">
                                                    <h6 class="msg-name">Oscar Garner <span class="msg-time float-end">8
                                                            min
                                                            ago</span></h6>
                                                    <p class="msg-info">Various versions have evolved over</p>
                                                </div>
                                            </div>
                                        </a>
                                        <a class="dropdown-item" href="javascript:;">
                                            <div class="d-flex align-items-center">
                                                <div class="user-online">
                                                    <img src="https://via.placeholder.com/110x110" class="msg-avatar"
                                                        alt="user avatar">
                                                </div>
                                                <div class="flex-grow-1">
                                                    <h6 class="msg-name">Katherine Pechon <span
                                                            class="msg-time float-end">15
                                                            min ago</span></h6>
                                                    <p class="msg-info">Making this the first true generator</p>
                                                </div>
                                            </div>
                                        </a>
                                        <a class="dropdown-item" href="javascript:;">
                                            <div class="d-flex align-items-center">
                                                <div class="user-online">
                                                    <img src="https://via.placeholder.com/110x110" class="msg-avatar"
                                                        alt="user avatar">
                                                </div>
                                                <div class="flex-grow-1">
                                                    <h6 class="msg-name">Amelia Doe <span class="msg-time float-end">22
                                                            min
                                                            ago</span></h6>
                                                    <p class="msg-info">Duis aute irure dolor in reprehenderit</p>
                                                </div>
                                            </div>
                                        </a>
                                        <a class="dropdown-item" href="javascript:;">
                                            <div class="d-flex align-items-center">
                                                <div class="user-online">
                                                    <img src="https://via.placeholder.com/110x110" class="msg-avatar"
                                                        alt="user avatar">
                                                </div>
                                                <div class="flex-grow-1">
                                                    <h6 class="msg-name">Cristina Jhons <span
                                                            class="msg-time float-end">2 hrs
                                                            ago</span></h6>
                                                    <p class="msg-info">The passage is attributed to an unknown</p>
                                                </div>
                                            </div>
                                        </a>
                                        <a class="dropdown-item" href="javascript:;">
                                            <div class="d-flex align-items-center">
                                                <div class="user-online">
                                                    <img src="https://via.placeholder.com/110x110" class="msg-avatar"
                                                        alt="user avatar">
                                                </div>
                                                <div class="flex-grow-1">
                                                    <h6 class="msg-name">James Caviness <span
                                                            class="msg-time float-end">4 hrs
                                                            ago</span></h6>
                                                    <p class="msg-info">The point of using Lorem</p>
                                                </div>
                                            </div>
                                        </a>
                                        <a class="dropdown-item" href="javascript:;">
                                            <div class="d-flex align-items-center">
                                                <div class="user-online">
                                                    <img src="https://via.placeholder.com/110x110" class="msg-avatar"
                                                        alt="user avatar">
                                                </div>
                                                <div class="flex-grow-1">
                                                    <h6 class="msg-name">Peter Costanzo <span
                                                            class="msg-time float-end">6 hrs
                                                            ago</span></h6>
                                                    <p class="msg-info">It was popularised in the 1960s</p>
                                                </div>
                                            </div>
                                        </a>
                                        <a class="dropdown-item" href="javascript:;">
                                            <div class="d-flex align-items-center">
                                                <div class="user-online">
                                                    <img src="https://via.placeholder.com/110x110" class="msg-avatar"
                                                        alt="user avatar">
                                                </div>
                                                <div class="flex-grow-1">
                                                    <h6 class="msg-name">David Buckley <span
                                                            class="msg-time float-end">2 hrs
                                                            ago</span></h6>
                                                    <p class="msg-info">Various versions have evolved over</p>
                                                </div>
                                            </div>
                                        </a>
                                        <a class="dropdown-item" href="javascript:;">
                                            <div class="d-flex align-items-center">
                                                <div class="user-online">
                                                    <img src="https://via.placeholder.com/110x110" class="msg-avatar"
                                                        alt="user avatar">
                                                </div>
                                                <div class="flex-grow-1">
                                                    <h6 class="msg-name">Thomas Wheeler <span
                                                            class="msg-time float-end">2 days
                                                            ago</span></h6>
                                                    <p class="msg-info">If you are going to use a passage</p>
                                                </div>
                                            </div>
                                        </a>
                                        <a class="dropdown-item" href="javascript:;">
                                            <div class="d-flex align-items-center">
                                                <div class="user-online">
                                                    <img src="https://via.placeholder.com/110x110" class="msg-avatar"
                                                        alt="user avatar">
                                                </div>
                                                <div class="flex-grow-1">
                                                    <h6 class="msg-name">Johnny Seitz <span class="msg-time float-end">5
                                                            days
                                                            ago</span></h6>
                                                    <p class="msg-info">All the Lorem Ipsum generators</p>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                    <a href="javascript:;">
                                        <div class="text-center msg-footer">View All Messages</div>
                                    </a>
                                </div>
                            </li>
                        </ul>
                    </div>
                 
                </nav>
            </div>
        </header> -->
        <div class="container-fluid mt-3">


            <div class="row">
                <div class="col-xl-12 mb-4">


                    <div class="card card-1 card-shadow ps-3">

                        <div class="card-body table-style  pb-0">

                            <div class="row">
                                <div class="col-xl-8">
                                    <p style="color:#5F78BA;margin-bottom: 5px; margin-top: 5px;">Hi</p>
                                    <h2 style="color: #5F78BA;"><b>{{FullName}} </b></h2>
                                    <p class="text"><img src="../../../assets/img/Group 53.png"
                                            class="img-fluid me-3">You have 1 new
                                        event</p>
                                    <!-- <button type="button" class="btn btn-outline-primary btn-sm py-1 px-3">View</button> -->
                                </div>
                                <div class="col-xl-4">
                                    <div id="carouselExampleDark" class="carousel carousel-dark slide"
                                        data-bs-ride="carousel">
                                        <div class="carousel-indicators">
                                            <button type="button" data-bs-target="#carouselExampleDark"
                                                data-bs-slide-to="0" class="active" aria-current="true"
                                                aria-label="Slide 1"></button>
                                            <button type="button" data-bs-target="#carouselExampleDark"
                                                data-bs-slide-to="1" aria-label="Slide 2"></button>
                                            <button type="button" data-bs-target="#carouselExampleDark"
                                                data-bs-slide-to="2" aria-label="Slide 3"></button>
                                        </div>
                                        <div class="carousel-inner">
                                            <div class="carousel-item active" data-bs-interval="10000">
                                                <img src="../../../assets/img/slider-1.png" class="d-block w-100"
                                                    alt="...">

                                            </div>
                                            <div class="carousel-item" data-bs-interval="2000">
                                                <img src="../../../assets/img/slider-1.png" class="d-block w-100"
                                                    alt="...">

                                            </div>
                                            <div class="carousel-item">
                                                <img src="../../../assets/img/slider-1.png" class="d-block w-100"
                                                    alt="...">

                                            </div>
                                        </div>
                                        <button class="carousel-control-prev" type="button"
                                            data-bs-target="#carouselExampleDark" data-bs-slide="prev">
                                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                            <span class="visually-hidden">Previous</span>
                                        </button>
                                        <button class="carousel-control-next" type="button"
                                            data-bs-target="#carouselExampleDark" data-bs-slide="next">
                                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                            <span class="visually-hidden">Next</span>
                                        </button>
                                    </div>
                                </div>
                            </div>





                        </div>
                    </div>
                </div>
            </div>
            <div class="row">

                <div class="col-xl-4 mb-3">
                    <div class="card  shadow-sm h-100 card1 text-dark" style="border-radius:0.900rem;">
                        <div class="card-body p-0">
                            <div class="shape1">

                            </div>
                            <h6 class="text-dark mt-3 ms-3 fw-bold">Number of Trainers</h6>


                            <div class="container-fluid mb-2">
                                <div class="row align-items-center">
                                    <div class="col-xl-9 col-9 ps-0">
                                        <table class="table table-borderless mb-0">

                                            <tr>

                                                <td style="width: 60%; font-size:14px !important;"
                                                    class="text-dark text-start ">Active</td>
                                                <td style="width:2%; font-size:12px !important;" class="text-dark ">:
                                                </td>
                                                <td style="font-size:14px !important;" class="text-dark text-start">
                                                    {{dashboard.TRAINERS_ACTIVE}}
                                                </td>

                                            </tr>

                                            <tr style="padding-bottom: 10px;">
                                                <td style="width: 60%;" class="text-dark text-start">Inactive</td>
                                                <td style="width: 2%;" class="text-dark ">:</td>
                                                <td class="text-dark text-start">{{dashboard.TRAINERS_INACTIVE}}</td>
                                            </tr>
                                            <tr style="padding-bottom: 10px;">
                                                <td style="width: 60%;" class="text-dark text-start ">Total </td>
                                                <td style="width:2%;" class="text-dark ">:</td>
                                                <td class="text-dark text-start">{{dashboard.TRAINERS_ACTIVE +
                                                    dashboard.TRAINERS_INACTIVE}}</td>
                                            </tr>

                                        </table>
                                    </div>
                                    <div class="col-xl-3 col-3 text-center justify-content-between"> <img
                                            src="../../../assets/img/Group 1000001047.png" class="img-fluid "></div>
                                </div>
                            </div>



                        </div>
                    </div>
                </div>

                <div class="col-xl-4 mb-3">
                    <div class="card  shadow-sm h-100 card2 text-dark" style="border-radius:0.900rem;">
                        <div class="card-body p-0">
                            <div class="shape2">

                            </div>
                            <h6 class="text-dark mt-3 ms-3 fw-bold">Number of Batches</h6>


                            <div class="container-fluid mb-2">
                                <div class="row align-items-center">
                                    <div class="col-xl-9 col-9 ps-0">
                                        <table class="table table-borderless mb-0">

                                            <tr>

                                                <td style="width: 60%; font-size:14px !important;"
                                                    class="text-dark text-start ">Inprogress</td>
                                                <td style="width:2%; font-size:12px !important;" class="text-dark ">:
                                                </td>
                                                <td style="font-size:14px !important;" class="text-dark text-start">
                                                    {{dashboard.BATCH_INPROGRESS}}
                                                </td>

                                            </tr>

                                            <tr style="padding-bottom: 10px;">
                                                <td style="width: 60%;" class="text-dark text-start">Completed</td>
                                                <td style="width: 2%;" class="text-dark ">:</td>
                                                <td class="text-dark text-start">{{dashboard.BATCH_COMPLETED}}</td>
                                            </tr>
                                            <tr style="padding-bottom: 10px;">
                                                <td style="width: 60%;" class="text-dark text-start ">Total Batches</td>
                                                <td style="width:2%;" class="text-dark ">:</td>
                                                <td class="text-dark text-start">{{dashboard.BATCH_COMPLETED +
                                                    dashboard.BATCH_INPROGRESS}}</td>
                                            </tr>

                                        </table>
                                    </div>
                                    <div class="col-xl-3 col-3 text-center justify-content-between"> <img
                                            src="../../../assets/img/img-dashboard/icon-4.png" class="img-fluid "></div>
                                </div>
                            </div>



                        </div>
                    </div>
                </div>

                <div class="col-xl-4 mb-3">
                    <div class="card  shadow-sm h-100 card3 text-dark" style="border-radius:0.900rem;">
                        <div class="card-body p-0">
                            <div class="shape3">

                            </div>
                            <h6 class="text-dark mt-3 ms-3 fw-bold">Number of Trainees</h6>


                            <div class="container-fluid mb-2">
                                <div class="row align-items-center">
                                    <div class="col-xl-9 col-9 ps-0">
                                        <table class="table table-borderless mb-0">

                                            <tr>

                                                <td style="width: 60%; font-size:14px !important;"
                                                    class="text-dark text-start ">Active</td>
                                                <td style="width:2%; font-size:12px !important;" class="text-dark ">:
                                                </td>
                                                <td style="font-size:14px !important;" class="text-dark text-start">
                                                    {{dashboard.TRAINEE_ACTIVE}}
                                                </td>

                                            </tr>

                                            <tr style="padding-bottom: 10px;">
                                                <td style="width: 60%;" class="text-dark text-start">Inactive</td>
                                                <td style="width: 2%;" class="text-dark ">:</td>
                                                <td class="text-dark text-start">{{dashboard.TRAINEE_INACTIVE}}</td>
                                            </tr>
                                            <tr style="padding-bottom: 10px;">
                                                <td style="width: 60%;" class="text-dark text-start ">Total </td>
                                                <td style="width:2%;" class="text-dark ">:</td>
                                                <td class="text-dark text-start">{{dashboard.TRAINEE_ACTIVE +
                                                    dashboard.TRAINEE_INACTIVE}}</td>
                                            </tr>

                                        </table>
                                    </div>
                                    <div class="col-xl-3 col-3 text-center justify-content-between"> <img
                                            src="../../../assets/img/img-dashboard/icon-3.png" class="img-fluid "></div>
                                </div>
                            </div>



                        </div>
                    </div>
                </div>

                <div class="col-xl-4 mb-3">
                    <div class="card  shadow-sm h-100 card4 text-dark" style="border-radius:0.900rem;">
                        <div class="card-body p-0">
                            <div class="shape4">

                            </div>
                            <h6 class="text-dark mt-3 ms-3 fw-bold">Fee Payments</h6>


                            <div class="container-fluid mb-2">
                                <div class="row align-items-center">
                                    <div class="col-xl-9 col-9 ps-0">
                                        <table class="table table-borderless mb-0">

                                            <tr>

                                                <td style="width: 60%; font-size:14px !important;"
                                                    class="text-dark text-start ">Paid</td>
                                                <td style="width:2%; font-size:12px !important;" class="text-dark ">:
                                                </td>
                                                <td style="font-size:14px !important;" class="text-dark text-start">
                                                    {{dashboard.PAID_AMOUNT |currency: 'INR'}}
                                                </td>

                                            </tr>

                                            <tr style="padding-bottom: 10px;">
                                                <td style="width: 60%;" class="text-dark text-start">Due</td>
                                                <td style="width: 2%;" class="text-dark ">:</td>
                                                <td class="text-dark text-start">{{dashboard.BALANCE_AMOUNT |currency:
                                                    'INR'}}</td>
                                            </tr>
                                            <tr style="padding-bottom: 10px;">
                                                <td style="width: 60%;" class="text-dark text-start "> Total </td>
                                                <td style="width:2%;" class="text-dark ">:</td>
                                                <td class="text-dark text-start">{{(dashboard.PAID_AMOUNT +
                                                    dashboard.BALANCE_AMOUNT) |currency: 'INR'}}</td>
                                            </tr>

                                        </table>
                                    </div>
                                    <div class="col-xl-3 col-3 text-center justify-content-between"> <img
                                            src="../../../assets/img/img-dashboard/icon-5.png" class="img-fluid "></div>
                                </div>
                            </div>



                        </div>
                    </div>
                </div>

                <!-- <div class="col-xl-4 mb-3">
                    <div class="card  shadow-sm h-100 card5 text-dark" style="border-radius:0.900rem;">
                        <div class="card-body p-0">
                            <div class="shape5">

                            </div>
                            <h6 class="text-dark mt-3 ms-3 fw-bold">Sessions</h6>


                            <div class="container-fluid mb-2">
                                <div class="row align-items-center">
                                    <div class="col-xl-9 col-9 ps-0">
                                        <table class="table table-borderless mb-0">

                                            <tr>

                                                <td style="width: 60%; font-size:14px !important;"
                                                    class="text-dark text-start ">Conducted</td>
                                                <td style="width:2%; font-size:12px !important;" class="text-dark ">:
                                                </td>
                                                <td style="font-size:14px !important;" class="text-dark text-start">
                                                    {{dashboard.session_conducted}}
                                                </td>

                                            </tr>

                                            <tr style="padding-bottom: 10px;">
                                                <td style="width: 60%;" class="text-dark text-start">On-going</td>
                                                <td style="width: 2%;" class="text-dark ">:</td>
                                                <td class="text-dark text-start">{{dashboard.ongoing_session}}</td>
                                            </tr>
                                            <tr style="padding-bottom: 10px;">
                                                <td style="width: 60%;" class="text-dark text-start "> Total Sessions
                                                </td>
                                                <td style="width:2%;" class="text-dark ">:</td>
                                                <td class="text-dark text-start">{{dashboard.session_conducted +
                                                    dashboard.ongoing_session}}</td>
                                            </tr>

                                        </table>
                                    </div>
                                    <div class="col-xl-3 col-3 text-center justify-content-between"> <img
                                            src="../../../assets/img/img-dashboard/icon-5.png" class="img-fluid "></div>
                                </div>
                            </div>



                        </div>
                    </div>
                </div> -->

                <div class="col-xl-4 mb-3">
                    <div class="card  shadow-sm h-100 card5 text-dark" style="border-radius:0.900rem;">
                        <div class="card-body p-0">
                            <div class="shape5">

                            </div>
                            <h6 class="text-dark mt-3 ms-3 fw-bold">Number of Courses</h6>


                            <div class="container-fluid mb-2">
                                <div class="row align-items-center">
                                    <div class="col-xl-9 col-9 ps-0">
                                        <table class="table table-borderless mb-0">

                                            <tr>

                                                <td style="width: 60%; font-size:14px !important;"
                                                    class="text-dark text-start ">Active</td>
                                                <td style="width:2%; font-size:12px !important;" class="text-dark ">:
                                                </td>
                                                <td style="font-size:14px !important;" class="text-dark text-start">
                                                    {{dashboard.COURSE_ACTIVE}}
                                                </td>

                                            </tr>

                                            <tr style="padding-bottom: 10px;">
                                                <td style="width: 60%;" class="text-dark text-start">Inactive</td>
                                                <td style="width: 2%;" class="text-dark ">:</td>
                                                <td class="text-dark text-start">{{dashboard.COURSE_INACTIVE}}</td>
                                            </tr>
                                            <tr style="padding-bottom: 10px;">
                                                <td style="width: 60%;" class="text-dark text-start "> Total Courses
                                                </td>
                                                <td style="width:2%;" class="text-dark ">:</td>
                                                <td class="text-dark text-start">{{dashboard.COURSE_ACTIVE +
                                                    dashboard.COURSE_INACTIVE}}</td>
                                            </tr>

                                        </table>
                                    </div>
                                    <div class="col-xl-3 col-3 text-center justify-content-between"> <img
                                            src="../../../assets/img/img-dashboard/icon-2.png" class="img-fluid "></div>
                                </div>
                            </div>



                        </div>
                    </div>
                </div>
            </div>








        </div>
    </div>

    <div class="overlay toggle-icon"></div>

    <!--Start Back To Top Button--> <a href="javaScript:;" class="back-to-top"><i class='bx bxs-up-arrow-alt'></i></a>
    <!--End Back To Top Button-->
</div>