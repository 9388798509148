<div class="ng-autocomplete">
    <ng-autocomplete [data]="CourseList" [searchKeyword]="keyword" placeholder="Select Course"
        (selected)='selectEvent($event)' (inputChanged)='onChangeSearch($event)' (inputFocused)='onFocused($event)'
        [itemTemplate]="itemTemplate" [notFoundTemplate]="notFoundTemplate">
    </ng-autocomplete>

    <ng-template #itemTemplate let-item>
        <a [innerHTML]="item.COURSE_NAME"></a>
    </ng-template>

    <ng-template #notFoundTemplate let-notFound>
        <div [innerHTML]="notFound"></div>
    </ng-template>
</div>