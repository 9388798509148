<div class="page-wrapper pt-4" >
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0">Assignments </h5>
                                    </div>

                                    <div class="card-body">
                                        <div class="text-right mb-1">
                                            <button type="button" class="btn btn-primary" (click)="add()">Add</button>
                                        </div>
                                        <br>

                                        <div *ngIf="assignments.length">
                                            <table id="patreggraph" datatable
                                                class=" table table-striped table-bordered mt-2 pt-2 table-sm small display"
                                                style="width:100%">
                                                <thead class="top" style="background-color: #624fd7; color:#ffffff;">
                                                    <tr>
                                                        <th> Course Name </th>
                                                        <th> Batch Schedule  </th>
                                                        <!-- <th> Chapter Name </th> -->
                                                        <th> Assignment Name </th>
                                                        <th> Start Date </th>
                                                        <th> End Date </th>
                                                        <th> Max Marks </th>
                                                        <th> Status </th>
                                                        <th> Action </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of assignments ">
                                                        <td>{{item.ASSIGNMENT_COURSE}}</td>
                                                        <td>{{item.ASSIGNMENT_COURSE_SCHEDULE}}</td>
                                                        <!-- <td>{{item.CHAPTER_NAME}}</td> -->
                                                        <td>{{item.ASSIGNMENT_NAME}}</td>
                                                        <td>{{item.ASSIGNMENT_START_DATE |date:'shortDate'}}</td>
                                                        <td>{{item.ASSIGNMENT_END_DATE |date:'shortDate'}}</td>
                                                        <td>{{item.ASSIGNMENT_MAX_MARKS}}</td>
                                                        <td>{{item.ASSIGNMENT_STATUS}}</td>
                                                        <td style="display: flex;">
                                                            <span data-bs-toggle="modal" data-bs-target="#myModal"
                                                                class="edit_icon" (click)="edit(item)"> <i
                                                                    class="fas fa-pen pt-1 mt-1"></i></span>
                                                        </td>

                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>