<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0">Forums Topics </h5>
                                    </div>
                                    <div class="card-body">
                                        <div *ngIf="false" class="text-right mb-1">
                                            <button type="button" class="btn btn-primary" data-bs-toggle="modal"
                                                data-bs-target="#myModal">Add Topic</button>
                                        </div>
                                        <div *ngIf="table.length">
                                            <table id="patreggraph" datatable
                                            class=" table table-striped table-bordered mt-2 pt-2 table-sm small display" style="width:100%">
                                                <thead class="top" style="background-color: #624fd7; color:#ffffff;">
                                                    <tr>
                                                        <th>
                                                            Course Name </th>
                                                        <th> Topic Name</th>
                                                        <th>Description</th>

                                                        <th>Status</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of table">
                                                        <td>{{item.COURSE_NAME}}</td>
                                                        <td>{{item.TOPIC}}</td>
                                                        <td>{{item.DESCRIPTION}}</td>
                                                        <td>{{item.STATUS}}</td>
                                                        <td style="display: flex;"> 
                                                            <span data-bs-toggle="modal" data-bs-target="#myModal"
                                                            src=""
                                                            class="edit_icon" (click)="edit(item)"> <i class="fas fa-pen pt-1 mt-1"></i></span >
                                                            <button
                                                                class="btn text-danger rem_1 pt-0"
                                                                (click)="delete(item)"> x</button></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="text-center" *ngIf="!table.length">
                                            No Records to display
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>



<div class="modal" id="myModal" data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog ">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header py-2">
                <h4 class="modal-title w-100"> Forums Approval</h4>
                <button type="button" (click)="close()" class="close" data-bs-dismiss="modal"><i class="fas fa-times"></i></button>
            </div>

            <!-- Modal body -->
            <div class="modal-body">
                <form class="well form-horizontal" [formGroup]="myForm" (ngSubmit)="onSubmit(myForm)">
                    <fieldset>
                        <div class="form-group row ">
                            <label class="col-md-4 control-label line_2 rem_1">
                                Course Name <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <input placeholder="" class="form-control" formControlName="MNAME" disabled>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label line_2 rem_1">
                                Topic Name <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <input placeholder="" class="form-control" formControlName="tNAME" disabled>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label line_2 rem_1">
                                Description </label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <textarea disabled placeholder="" class="form-control"
                                        formControlName="MDESCRIPTION"></textarea>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label"> Is Approved</label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="radio"> <strong>:</strong> &nbsp;
                                    <div class="form-check-inline">
                                        <label class="form-check-label">
                                            <input value='1' [(ngModel)]='isApproved' type="radio"
                                                formControlName='MSTATUS' class="form-check-input"
                                                name="MSTATUS">Approve
                                        </label>
                                    </div>
                                    <div class="form-check-inline pt-4">
                                        <label class="form-check-label">
                                            <input value='0' type="radio" [(ngModel)]='isApproved'
                                                formControlName='MSTATUS' class="form-check-input" name="MSTATUS">Reject
                                        </label>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </fieldset>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" (click)="onSubmit(myForm)" class="btn btn-sm  btn-save"
                    [disabled]="myForm.invalid">Save</button>

                <button type="button" class="btn btn-danger btn-sm" id='md_close' (click)="close()"
                    data-bs-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>