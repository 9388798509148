<body>
    <div class="site-mobile-menu site-navbar-target style-1 ">
        <div class="site-mobile-menu-header">
            <div class="site-mobile-menu-close mt-3">
                <span class="icon-close2 js-menu-toggle"></span>
            </div>
        </div>
        <div class="site-mobile-menu-body">

        </div>
    </div>

    <app-header-new></app-header-new>
    <div>

        <div class="text-center">
            <h4 style="font-size:36px; top:40%; right:22%;    font-weight: 700;     padding: 10% 0 10% 0;">
                Thanks for Registering with Shiksion.com.<br />
                Note :Shortly Support team will connect you back
            </h4>

        </div>
        <div>
            <app-footer-new></app-footer-new>
        </div>
    </div>
</body>