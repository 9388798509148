import { BaseComponent } from 'src/app/pages/base.component';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';
declare var $: any;
declare function googleTranslate(): any;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent extends BaseComponent implements OnInit {
  userName: string = sessionStorage.getItem('Username');
  table: Array<any> = [];
  viewMsg: Array<any> = [];
  message: Array<any> = [];
  levelUpdate: any;
  notifications: Array<any> = [];
  notification: Array<any> = [];
  viewNoti: Array<any> = [];
  OPERATION: any;
  ROLEID = sessionStorage.getItem('RoleId');
  UserRoleName: string;
  userImage: any;
  NotificationCount: number = 0;
  constructor(private route: Router, CommonService: CommonService, toastr: ToastrService) {
    super(CommonService, toastr);
    this.loadMessage();
    // this.UserRoleName = (+sessionStorage.USERTYPE == 25) ? 'Trainer' : 'Trainee';
    this.UserRoleName = this.RoleName;
    this.loadNotification();

  }

  ngOnInit(): void {
    this.CommonService.userImage.subscribe((res: any) => {
      this.userImage = res;
    });
    // this.userImage = sessionStorage.profileImage;
    googleTranslate();
  }

  Signout() {
    let { hostname } = location;
    const { company_id, cerficateimage_path, favicon_path, homepageimage_path, landingpageimage_path } = sessionStorage;
    sessionStorage.clear();
    if (!["localhost", "shiksion.com"].includes(hostname)) {

      sessionStorage.company_id = company_id;
      sessionStorage.cerficateimage_path = cerficateimage_path;
      sessionStorage.favicon_path = favicon_path;
      sessionStorage.homepageimage_path = homepageimage_path;
      sessionStorage.landingpageimage_path = landingpageimage_path;
    }
    this.route.navigate(['']);
  }

  changePassword() {
    this.route.navigate(['HOME/change-password']);
  }

  menuClick() {
    $('#sidebar').toggleClass('slide');
  }
  loadMessage() {
    this.notifications = [];
    if (this.ROLEID == '1') {
      this.OPERATION = 'GETNOTIFICATIONS'
    } else if (this.ROLEID == '2') {
      this.OPERATION = 'TRAINERNOTIFICATIONS'
    } else if (this.ROLEID == '3') {
      this.OPERATION = 'STUDENTNOTIFICATIONS'
    }
    // this.CommonService.getCall(`Notification/GetNotification/${this.OPERATION}/${sessionStorage.getItem('TenantCode')}/${sessionStorage.getItem('UserId')}`).subscribe((res: any) => {
    //  // this.table = [];
    //     this.table = res;
    // }, e => { console.log(e) })
  }


  delete(id: any, type: any, notificationid: any) {
    if (id) {
      type = 2;
    }
    else if (notificationid) {
      type = 1;
    }
    this.CommonService.getCall(`Notification/DeleteNotification/${id}/${type}`).subscribe((res: any) => {

    })
  }

  close() { 
    this.getUnViewdNotificationCount();
  }

  close1(e: any) {
  }

  getUnViewdNotificationCount() {
    
    return this.notification.filter(m => m.notification_read_status == 0).length;
  }


  apply() {
    document.getElementById('Noti_close')?.click();
  }

  loadNotification() {
    this.notification = [];
    this.CommonService.getCall(`EmsAlerts/GetNotificationsByUser/${sessionStorage.getItem('UserId')}`).subscribe((res: any) => {
      this.notification = res;
      this.NotificationCount = this.notification.filter(m => m.notification_read_status == 0).length;
    })
  }

  viewNotification(event: any) {
    this.viewNoti = [];
    this.viewNoti = +event == 0 ? this.notification : this.notification.filter(e => e.internal_notification_id == event);

    this.CommonService.getCall(`EmsAlerts/UpdateNotificationreadStatus/${event}/${1}`).subscribe((res: any) => {

    })
  }


  viewMessage(event: any) {
    this.viewNoti = [];
    this.viewNoti = +event == 0 ? this.notification : this.notification.filter(e => e.ID == event);
  }



}
