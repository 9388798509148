<div class="page-wrapper">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">

                    <div class="container-fluid">

                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0">Level Of Learner</h5>
                                    </div>
                                    <div class="card-body">

                                        <table id="patreggraph" datatable [dtOptions]="dtOptions"  [dtTrigger]="dtTrigger"
                                            class="table table-striped table-bordered mt-2 pt-2 table-sm small display"
                                            style="width:100%">
                                            <thead class="top" style="background-color: #624fd7; color:#ffffff;">
                                                <tr>
                                                    <th> Seq.No. </th>
                                                    <th> Learner </th>
                                                    

                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr  *ngFor="let label of labelOfLearner;let i=index">
                                                    <td>{{i+1}}</td>
                                                    <td>{{label.DICTIONARYNAME}}</td>
                                                    
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> 