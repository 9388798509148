

<!-- <div class="modal" id="myModal_lan" data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog  modal-md">
        <div class="modal-content"> -->

            <!-- Modal Header -->
            <!-- <div class="modal-header py-2">
                <h4 *ngIf="!isEdit" class="modal-title"> Add Language </h4>
                <h4 *ngIf="isEdit" class="modal-title"> Edit  Language</h4>
                <button type="button" (click)="close()" class="close" data-bs-dismiss="modal">&times;</button>
            </div> -->

            <!-- Modal body -->
            <div class="custom_container">
                <form class="well form-horizontal" [formGroup]="myForm" (ngSubmit)="onSubmit(myForm)">
                    <fieldset>
                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label"> Language  <span
                                    class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <select  formControlName='Language'
                                    class="form-select form-select-sm shadow-sm rounded-lg">
                                    <option value="" selected>Select Language</option>
                                    <option *ngFor='let item of languages' [value]='item.DICTIONARYNAME'>{{item.DICTIONARYNAME}}</option>
                                </select>

                                </div>
                                <div *ngIf="myForm.get('Language').touched">
                                    <span class="text-danger" *ngIf="myForm.get('Language').hasError('required')">
                                        This field is required.
                                    </span>
                                </div>
                               
                            </div>
                        </div>
                        <!-- <div class="form-group row pt-4">
                            <label class="col-md-4 control-label"> Employement Status <span
                                    class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <select  formControlName='ProficiencyLevel'
                                    class="form-control form-control-sm shadow-sm rounded-lg">
                                    <option value="" selected>Select Employement Status</option>
                                    <option *ngFor='let item of levels' [value]='item.DICTIONARYNAME'>{{item.DICTIONARYNAME}}</option>
                                </select>

                                </div>
                        
                            </div>
                        </div> -->
                             
                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label line_2 rem_1">
                                Read Language </label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <input type="checkbox" formControlName="ReadL">
                                   
                                </div>
                                
                            </div>
                        </div>
                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label line_2 rem_1">
                               Write Language</label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <input type="checkbox" formControlName="WriteL">
                                    
                                </div>
                              
                            </div>
                        </div>
                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label line_2 rem_1">
                                Speak Language </label>
                                <div class="col-md-8 inputGroupContainer">
                                    <div class="input-group">
                                        <input type="checkbox" formControlName="SpeakL">
                                        
                                    </div>
                                  
                                </div>
                        </div>
                      
                    </fieldset>
                </form>

            </div>
            <!-- <div class="modal-footer">
                <button type="button" *ngIf="!isEdit" class="btn btn-sm btn-save" (click)="onSubmit(myForm)"
                    [disabled]="myForm.invalid">Save</button>
                <button type="button" *ngIf="isEdit" class="btn btn-sm btn-save" (click)="onSubmit(myForm)"
                    [disabled]="myForm.invalid">Update</button>
                <button type="button" class="btn btn-sm btn-danger" id="md_close_lan" (click)="close()"
                    data-bs-dismiss="modal">Close</button>
            </div> -->
            <div class="text-center pt-4" >
                <button type="button" class="btn btn-sm  btn-success"
                (click)="onSubmit(myForm)"  >{{btnTitle}}</button> &nbsp;
                 <button type="button" class="btn btn-sm btn-danger"
                    (click)="clearForm()">Clear</button> 
            </div> 
        <!-- </div>
    </div>
</div> -->

<div class="pt-5">
    <!-- <div class="text-right">
        <button type="button" class="btn btn-primary" (click)="add()" data-bs-toggle="modal"   data-bs-target="#myModal_lan">Add</button>

    </div> -->
    <table *ngIf='table.length' class="table table-striped table-responsive-md btn-table table-bordered text-dark">
        <thead class="top" style="background-color: #624FD7; color:#ffffff;">
            <tr>
                <th>Language</th>
                <!-- <th>Proficiency Level</th> -->
                <th>Read</th>
                <th>Write</th>
                <th>Speak</th>          
                <th>Operation</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor='let item of table;let i=index'>
                <td>{{item.Language }}</td>
                <!-- <td>{{item.ProficiencyLevel }}</td> -->
                <td>{{item.ReadL }}</td>
                <td>{{item.WriteL }}</td>
                <td>{{item.SpeakL }}</td>
                <td style="display: flex;"> 
                    <span class="edit_icon" style="cursor: pointer;" (click)="edit(item,i)"> 
                    <i class="fas fa-pen pt-1 mt-1"></i></span ></td>
            </tr>
        </tbody>
    </table>
    <!-- <p class="text-center" *ngIf='!table.length'>No Records</p> -->
</div>