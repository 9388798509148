<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0 ">Schedule Assessment</h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="text-right">
                                            <button type="button" class="btn btn-primary mb-1" data-bs-toggle="modal"
                                                data-bs-target="#myModal" (click)="add()">Add</button>
                                        </div>
                                        <div *ngIf="table.length">
                                            <table id="patreggraph" datatable
                                            class=" table table-striped table-bordered mt-2 pt-2 table-sm small display" style="width:100%">
                                            <thead class="top" style="background-color: #624fd7; color:#ffffff;">
                                                    <tr>
                                                        <th> Assessment Name </th>
                                                        <th> Course Name </th>
                                                        <th> Batch Schedule Name </th>
                                                        <th> Start Date </th>
                                                        <th> Edit </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of table">
                                                        <td>{{item.ASSESSMENT_ASSESSMENT_NAME}}</td>
                                                        <td>{{item.COURSE_NAME}}</td>
                                                        <td>{{item.COURSESHD_NAME}}</td>
                                                        <td>{{item.SA_DATE | date:'dd-MM-yyyy'}}</td>
                                                        <td style="display: flex;"> 
                                                            <span data-bs-toggle="modal" data-bs-target="#myModal"
                                                            class="edit_icon" (click)="edit(item)"> <i class="fas fa-pen pt-1 mt-1"></i></span >
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="text-center" *ngIf="!table.length">
                                            No Records to display
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="myModal" data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header py-2">
                <h6 *ngIf="!isEdit" class="modal-title w-100"> Add Schedule Assessment</h6>
                <h6 *ngIf="isEdit" class="modal-title w-100"> Edit Schedule Assessment</h6>
                <button type="button" (click)="close()" class="close" data-bs-dismiss="modal"><i class="fas fa-times"></i></button>
            </div>

            <!-- Modal body -->
            <div class="modal-body">
                <form class="well form-horizontal" [formGroup]="myForm" (ngSubmit)="onSubmit(myForm)">
                    <fieldset>

                        <div class="form-group row">
                            <label class="col-md-4 control-label"> Course Name <span
                                    class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <select [(ngModel)]='courceId' (change)="courseChange()"
                                        class="form-select form-select-sm shadow-sm rounded-lg"
                                        formControlName="SA_COURSE_ID">
                                        <option value="" [selected]="true">select</option>
                                        <option *ngFor="let course of courses" [value]='course.COURSE_ID'>
                                            {{course.COURSE_NAME}}
                                        </option>

                                    </select>
                                </div>
                            </div>
                        </div>

                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label"> Course Schedule </label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <select [(ngModel)]='scheduleId ' (change)="sheduleChange()"
                                        class="form-select form-select-sm shadow-sm rounded-lg"
                                        formControlName="SA_COURSE_SCHEDULE_ID">
                                        <option value="" [selected]="true">select</option>
                                        <option *ngFor="let schedule of scheduls" [value]='schedule.COURSESHD_ID'>
                                            {{schedule.COURSESHD_NAME}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label">Topics <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <select *ngIf="false" class="form-select form-select-sm shadow-sm rounded-lg">
                                        <option value="" [selected]="true">select</option>
                                        <option *ngFor="let course of courses" [value]='course.COURSE_ID'>
                                            {{course.COURSE_NAME}}
                                        </option>

                                    </select>
                                    <ng-multiselect-dropdown formControlName='SA_CHAPTERS' style="width:96%"
                                        *ngIf="isData" [placeholder]="'select cource'" [settings]="dropdownSettings"
                                        [data]="dropdownList" (onSelect)="onItemSelect($event)"
                                        (onSelectAll)="onSelectAll($event)" [(ngModel)]="selectedItems">
                                    </ng-multiselect-dropdown>
                                </div>
                            </div>
                        </div>

                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label"> Assessment Name </label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <select class="form-select form-select-sm shadow-sm rounded-lg"
                                        formControlName="SA_ASSESSMENT_ID">
                                        <option value="" [selected]="true">select</option>
                                        <option *ngFor="let ass of assessments" [value]='ass.ASSESSMENT_ID'>
                                            {{ass.ASSESSMENT_ASSESSMENT_NAME}}
                                        </option>

                                    </select>
                                </div>
                            </div>
                        </div>

                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label line_2 rem_1">
                                Date <span class="text-danger">*</span> </label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <input type="date" formControlName='SA_DATE' placeholder="" class="form-control">
                                </div>
                            </div>
                        </div>

                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label line_2 rem_1">
                                Start Time <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <input type="time" [(ngModel)]="startTime" formControlName='SA_START_TIME'
                                        placeholder="" class="form-control">
                                </div>
                            </div>
                        </div>
                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label line_2 rem_1">
                                End Time <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <input type="time" (change)="timeChange($event.target.value)"
                                        formControlName='SA_END_TIME' placeholder="" class="form-control">
                                </div>
                            </div>
                        </div>

                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label">Training Mode <span
                                    class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <select formControlName='SA_TRAINING_MODE'
                                        class="form-select form-select-sm shadow-sm rounded-lg">
                                        <option value="" [selected]="true">select</option>
                                        <option value=1>Offline</option>
                                        <option value=2>Online </option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div class="form-group row pt-4" id="isChecked" *ngIf="isProctoring">
                            <label class="col-md-4 control-label line_2 rem_1">
                                Is Proctoring </label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <input type="checkbox" class="checkbox" formControlName="ALLOW_PROCTORING"
                                        (change)="proctoring($event)">
                                </div>
                            </div>
                        </div>

                        <div class="form-group row pt-4" *ngIf="isChecked">
                            <label class="col-md-4 control-label">Proctoring Types <span
                                    class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">

                                    <ng-multiselect-dropdown formControlName='PROCTORING_TYPE' style="width:96%"
                                        *ngIf="isDataProctoring" [placeholder]="'select Proctoring'"
                                        [settings]="dropdownSettings" [data]="dropdownListProctoring"
                                        (onSelect)="onItemSelect($event)" (onSelectAll)="onSelectAll($event)"
                                        [(ngModel)]="selectedProctoring">
                                    </ng-multiselect-dropdown>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row pt-4" *ngIf="isChecked ">
                            <label class="col-md-4 control-label line_2 rem_1">
                                Remainder <span class="text-danger">*</span> </label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <input type="number" formControlName='REMINDER_COUNT' class="form-control">
                                </div>
                            </div>
                        </div>

                    </fieldset>
                </form>

            </div>
            <div class="modal-footer">
                <button type="button" *ngIf="!isEdit" class="btn btn-sm  btn-save" (click)="onSubmit(myForm)"
                    [disabled]="myForm.invalid">Save</button>
                <button type="button" *ngIf="isEdit" class="btn btn-sm  btn-save" (click)="onSubmit(myForm)"
                    [disabled]="myForm.invalid">Update</button>
                <button type="button" class="btn btn-danger btn-sm" id="md_close" (click)="close()"
                    data-bs-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>