<!-- <li metis-menu class="mt-0 ">
    <a href="#master_data" class="has-arrow">
      <div class="menu-title">Master Data</div>
    </a>
    <ul class="parent-icon" id="master_data"> -->
<!-- <li><a class="has-arrow" [routerLink]="['/home/my-language']"><i class="bx bx-right-arrow-alt"></i>Languages </a></li>
      <li><a class="has-arrow" [routerLink]="['/home/levelof-learner']"><i class="bx bx-right-arrow-alt"></i>Level of Learner </a></li> -->
<!-- <li><a class="has-arrow"><i class="bx bx-right-arrow-alt"></i>Payment Type </a></li> -->
<!-- <li><a class="has-arrow"><i class="bx bx-right-arrow-alt"></i>Folder Management</a></li> -->
<!-- </ul>
  </li> -->
<!-- <div class="wrapper" style="margin-top: 9.6vh">
  <ul class="style-1 shadow nav-active1" style="display:block;">
    
    <li metis-menu class="mt-0 ">
      <a href="#myprofile" class="has-arrow" [routerLink]="['/home/userRegistration']">
        <div class="menu-title">My Profile</div>
      </a>
    </li> -->

<!-- <li metis-menu class="mt-0 ">
      <a href="#courses_setup" class="has-arrow">
        <div class="menu-title">Course Setup</div>
      </a>
      <ul class="parent-icon" id="courses_setup"> -->
<!-- <li><a class="has-arrow" [routerLink]="['/home/courseCategory']"><i class="bx bx-right-arrow-alt" ></i>Category </a></li> -->
<!-- <li><a class="has-arrow" [routerLink]="['/home/courses']"><i class="bx bx-right-arrow-alt"></i>Course </a></li>
        <li><a class="has-arrow" [routerLink]="['/home/courseSchedule']"><i class="bx bx-right-arrow-alt"></i>Batch
            Schedule </a></li>
        <li><a class="has-arrow" [routerLink]="['/home/chapters']"><i class="bx bx-right-arrow-alt"></i>Topics</a></li>
        <li><a class="has-arrow" [routerLink]="['/home/enrolled-student']"><i class="bx bx-right-arrow-alt"></i>Enroll
            Students </a></li>
        <li><a class="has-arrow" [routerLink]="['/home/contentmanager']"><i class="bx bx-right-arrow-alt"></i>Content
            Manager</a></li>
        <li><a class="has-arrow" [routerLink]="['/home/configurematerials']"><i
              class="bx bx-right-arrow-alt"></i>Configure Materials</a></li>

      </ul>
    </li> -->

<!-- <li class="parent"><a href="" class="has-arrow"><span class="icon"><i
      class="fas fa-book"></i></span>&nbsp;&nbsp;<span class="text">Course Setup</span></a>
<div class="wrapper">
  <ul>
    <li><a href="" [routerLink]="['/home/courses']">Course</a></li>
    <li><a href="" [routerLink]="['/home/courseSchedule']">Batch Schedule</a></li>
    <li><a href="" [routerLink]="['/home/chapters']">Topics</a></li>
    <li><a href="" [routerLink]="['/home/enrolled-student']">Enroll Students</a></li>
    <li><a href="" [routerLink]="['/home/contentmanager']">Content Manager</a></li>
    <li><a href=""  [routerLink]="['/home/configurematerials']">Configure Materials</a></li>
  </ul>
</div>


    <li metis-menu class="mt-0 ">
      <a href="#myreviews" class="has-arrow" [routerLink]="['/home/my-reviews']">
        <div class="menu-title">My Reviews</div>
      </a>
    </li>
    <li metis-menu class="mt-0 ">
      <a href="#mypayment" class="has-arrow" [routerLink]="['/home/tutor-payments']">
        <div class="menu-title">My Payments</div>
      </a>
    </li> -->


<!-- <li metis-menu class="mt-0 ">
    <a href="#assessment" class="has-arrow">
      <div class="menu-title">Assessment</div>
    </a>
    <ul class="parent-icon" id="assignments">
      <li><a class="has-arrow" [routerLink]="['/home/assessmentQuestionnaire']"><i class="bx bx-right-arrow-alt" ></i>Assessment Questionnaire</a></li>
      <li><a class="has-arrow" [routerLink]="['/home/masterAssessment']"><i class="bx bx-right-arrow-alt" ></i>Master Assessment</a></li>
      <li><a class="has-arrow" [routerLink]="['/home/scheduleAssessment']"><i class="bx bx-right-arrow-alt" ></i>Schedule Assessment</a></li>
      <li><a class="has-arrow" [routerLink]="['/home/evaluateAssessment']"><i class="bx bx-right-arrow-alt" ></i>Evaluate Assessment</a></li>
    </ul>
  </li> -->

<!-- <li metis-menu class="mt-0 ">
      <a href="#assignments" class="has-arrow">
        <div class="menu-title">Assignments</div>
      </a>
      <ul class="parent-icon" id="assignments">
        <li><a class="has-arrow" [routerLink]="['/home/assignments']"><i class="bx bx-right-arrow-alt"></i>Assignments
          </a></li>
        <li><a class="has-arrow" [routerLink]="['/home/evaluateAssignments']"><i
              class="bx bx-right-arrow-alt"></i>Evalute Assignment </a></li>

      </ul>
    </li>

    <li metis-menu class="mt-0 ">
      <a href="#conference`" class="has-arrow">
        <div class="menu-title">Conference</div>
      </a>
      <ul class="parent-icon" id="conference">
        <li><a class="has-arrow" [routerLink]="['/home/meetings']"><i class="bx bx-right-arrow-alt"></i>Recording
            Storage</a></li>
        <li><a class="has-arrow" [routerLink]="['/home/eventRequest']"><i class="bx bx-right-arrow-alt"></i>Event
            Request</a></li>
        <li><a class="has-arrow" [routerLink]="['/home/joinconference']"><i class="bx bx-right-arrow-alt"></i>Join
            Conference</a></li>
      </ul>

    </li>
  </ul>
</div> -->




<div class="wrapper" style="height: auto; overflow: hidden">
  <!--sidebar wrapper -->
  <div class="sidebar-wrapper" data-simplebar="true">

    <div class="sidebar-menu-wrapper">
      <div class="sidebar-header">
        <div>
          <img id="homepageimage_path" src="../../assets/img/samvaad_tutor_logo.png" class="img-fluid" alt="logo icon">
        </div>
        <!-- <div>
            <h4 class="logo-text">LMS</h4>
          </div> -->
        <!-- <div class="toggle-icon ms-auto"><i class='bx bx-arrow-to-left'></i>
          </div> -->
      </div>
      <div class="wrapper" style="margin-top: 9.6vh">
        <ul class="style-1 shadow nav-active1" style="display:block;">

          <li class="parent"><a [routerLink]="RoleId==1?['/HOME/usersRegistrationList']:['/HOME/userRegistration']"
              class=""><span class="icon"><i class="fas fa-user"></i></span>&nbsp;&nbsp;<span class="text">My
                Profile</span></a>
          </li>

          <li class="parent"><a class="has-arrow"><span class="icon"><i class="fas fa-book"></i></span>&nbsp;&nbsp;<span
                class="text">Course Setup</span></a>
            <div class="wrapper">
              <ul>
                <li><a [routerLink]="['/HOME/courses']">Course</a></li>
                <li><a [routerLink]="['/HOME/courseSchedule']">Batch Schedule</a></li>
                <li><a [routerLink]="['/HOME/chapters']">Topics</a></li>
                <li><a [routerLink]="['/HOME/enrolled-student']" *ngIf="ScreenPermission">Enroll Students</a></li>
                <li><a [routerLink]="['/HOME/contentmanager']">Content Manager</a></li>
                <li><a [routerLink]="['/HOME/configurematerials']">Configure Materials</a></li>
                <li><a [routerLink]="['/HOME/notification']">Notification</a></li>
              </ul>
            </div>
          </li>
          <div *ngIf="ScreenPermission">
            <li class="parent"><a [routerLink]="['/HOME/my-reviews']" class=""><span class="icon"><i
                    class="fas fa-star"></i></span>&nbsp;&nbsp;<span class="text">My Reviews</span></a>
            </li>

            <li class="parent"><a [routerLink]="['/HOME/tutor-payments']" class=""><span class="icon"><i
                    class="fas fa-file-invoice-dollar"></i></span>&nbsp;&nbsp;<span class="text">My Payments</span></a>
            </li>
          </div>

          <li class="parent"><a class="has-arrow"><span class="icon"><i class="fas fa-pen"></i></span>&nbsp;&nbsp;<span
                class="text">Assignments</span></a>
            <div class="wrapper">
              <ul>
                <li><a [routerLink]="['/HOME/assignments']">Assignments</a></li>
                <li><a [routerLink]="['/HOME/evaluateAssignments']">Evaluate Assignment</a></li>
              </ul>
            </div>
          </li>

          <li class="parent"><a class="has-arrow"><span class="icon"><i class="fas fa-pen"></i></span>&nbsp;&nbsp;<span
                class="text">Assessment</span></a>
            <div class="wrapper">
              <ul>
                <li><a [routerLink]="['/HOME/assessmentQuestionnaire']">Assessment Questionnaire</a></li>
                <li><a [routerLink]="['/HOME/masterAssessment']">Master Assessment</a></li>
                <li><a [routerLink]="['/HOME/scheduleAssessment']">Schedule Assessment</a></li>
                <li><a [routerLink]="['/HOME/evaluateAssessment']">Evaluate Assessment</a></li>
                <li><a [routerLink]="['/HOME/examResult']">Exam Result</a></li>
                <li><a [routerLink]="['/HOME/assessmentResult']">Assessment Results</a></li>
              </ul>
            </div>
          </li>



          <li class="parent" *ngIf="RoleId==1"><a [routerLink]="['/HOME/configure-images']">
              <span class="icon"><i class="fas fa-user"></i></span>&nbsp;&nbsp;<span class="text">Configure
                Images</span></a>
          </li>

          <li class="parent" *ngIf="RoleId==1"><a class="has-arrow"><span class="icon"><i
                  class="fas fa-book"></i></span>&nbsp;&nbsp;<span class="text">Reports</span></a>
            <div class="wrapper">
              <ul>
                <li><a [routerLink]="['/HOME/course-report']">Course Report</a></li>
                <li><a [routerLink]="['/HOME/trainer-report']">Trainer Report</a></li>
                <li><a [routerLink]="['/HOME/trainee-report']">Trainee Report</a></li>
                <li><a [routerLink]="['/HOME/fee-report']">Fee Report</a></li>

              </ul>
            </div>


            <!-- <li class="parent"><a class="has-arrow"><span class="icon"><i
            class="fas fa-satellite-dish"></i></span>&nbsp;&nbsp;<span class="text">Conference</span></a>
      <div class="wrapper">
        <ul>
          <li><a [routerLink]="['/home/meetings']">Recording Storage</a></li>
          <li><a [routerLink]="['/home/eventRequest']">Event Request</a></li>
          <li><a [routerLink]="['/home/joinconference']">Join Conference</a></li>
        </ul>
      </div>
    </li> -->





        </ul>
      </div>

    </div>
  </div>
</div>