<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0 ">Fee Report</h5>
                                    </div>
                                    <div class="card-body">

                                        <div class="form-group ">
                                            <div class="row">
                                                <div class="col-md-3">
                                                    <label for="sel1">Course Name : </label>
                                                    <select [(ngModel)]='course_id '
                                                        class="form-select form-select-sm shadow-sm rounded-lg"
                                                        id="sel1" (change)="FeeReportChange()">
                                                        <option value="0" [selected]="true">Select All</option>
                                                        <option *ngFor="let course of courcesList"
                                                            [value]='course.COURSE_ID'>
                                                            {{course.COURSE_NAME}}
                                                        </option>

                                                    </select>
                                                </div>

                                                <div class="col-md-3">
                                                    <label for="sel1">Student Level : </label>
                                                    <select (change)="FeeReportChange()" [(ngModel)]="studentLevel_id"
                                                        class="form-select form-select-sm shadow-sm rounded-lg">
                                                        <option value="" selected>Select</option>
                                                        <option *ngFor='let item of student_level_List'
                                                            [value]='item.DICTIONARYID'>{{item.DICTIONARYNAME}}
                                                        </option>
                                                    </select>
                                                </div>

                                                <div class="col-md-3">
                                                    <label for="sel1">Payment Status : </label>
                                                    <select (change)="FeeReportChange()" [(ngModel)]="paymentStatus_id"
                                                        class="form-select form-select-sm shadow-sm rounded-lg">
                                                        <option value=null selected>Select</option>
                                                        <option *ngFor='let item of paymentStatus' [value]='item.id'>
                                                            {{item.name}}
                                                        </option>

                                                    </select>
                                                </div>
                                            </div>&nbsp;


                                            <div  class="table-responsive">
                                                <table id="feeReport" datatable [dtOptions]="dtOptions"
                                                [dtTrigger]="dtTrigger"
                                                    class=" table-striped table-bordered mt-2 pt-2 table-sm small display"
                                                    style="width:100%">
                                                    <thead class="top"
                                                        style="background-color: #624FD7; color:#ffffff;">
                                                        <tr style="white-space: nowrap;">
                                                            <th> S.No </th>
                                                            <th> Trainee Name</th>
                                                            <th> Student Level </th>
                                                            <th> Trainer Name </th>
                                                            <th> Course Fee </th>
                                                            <th> Paid Amount </th>
                                                            <th> Due Amount </th>


                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let item of feeReportList; let i=index">
                                                            <td>{{i+1}}</td>
                                                            <td>{{item.name}}</td>
                                                            <td>{{item.studentLevelName}}</td>
                                                            <!-- <td>{{item.courseshd_student_level}}</td> -->
                                                            <td>{{item.trainername}}</td>
                                                            <td>{{item.amount | currency:'INR'}}</td>
                                                            <td>{{item.total_amount_paid | currency:'INR'}}</td>
                                                            <td>{{item.due_amount | currency:'INR'}}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <!-- <div class="text-center" *ngIf="!feeReportList.length">
                                                No Records to display
                                            </div> -->
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>