import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {

  changePasswordForm: FormGroup;
  password: any;
  validate: boolean = false;
  RoleId = sessionStorage.RoleId;

  constructor(private route: Router, private CommonService: CommonService, private toastr: ToastrService, private fb: FormBuilder) {
  }

  ngOnInit(): void {
    document.getElementById('btnOpenModel').click();
    (<HTMLInputElement>document.getElementById('txtOldpassword')).focus();

    this.changePasswordForm = this.fb.group({
      OLD_PWD: ['', Validators.required,],
      NEW_PWD: ['', Validators.required,],
      CONFIRM_PWD: ['', Validators.required,],
    })
  }

  onSubmit(form: FormGroup) {
    this.validate = true;
    let value = form.value;
    const { PASSWORD } = sessionStorage;
    if(PASSWORD!=value.OLD_PWD){
      this.toastr.warning('old password not match');
      return;
    }
    if (value.OLD_PWD == value.NEW_PWD) {
      this.toastr.warning('old password and new password should not match');
    } else if (value.NEW_PWD != value.CONFIRM_PWD) {
      this.toastr.warning('passward not match ');
    } else if (value.NEW_PWD == value.CONFIRM_PWD) {

      let payLoad = {
        TENANT_CODE: sessionStorage.getItem('TenantCode'),
        UserId: sessionStorage.getItem('UserId'),
        password: value.CONFIRM_PWD,

      };

      this.CommonService.postCall('Account/ChangePassword', payLoad).subscribe((res: any) => {
        this.toastr.success(' Password generated  Successfully');
        document.getElementById('btnCloseModel').click();
      }, err => {
      });
    }

  }

  close() {
    this.validate = false;
    this.route.navigate([(this.RoleId == 2) ? 'HOME/trainer-dashboard' : 'HOME/my-courses']);
  }
}
