<div class="contact_details">
    <div class="text-right pt-2">
        <!-- <button type="button" class="btn btn-primary mb-1" (click)="add()">Add</button> -->

    </div>
    <div class="pt-2">
        <table class="table table-striped table-responsive-md btn-table table-bordered text-dark">
            <thead class="top" style="background-color: #624FD7; color:#ffffff;">
                <tr>
                    <th>Is Primary</th>
                    <th>Email Id</th>
                    <th>Primary No</th>
                    <th>Secondary No</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor='let item of table;let i=index'>
                    <td><input type="checkbox" [(ngModel)]='item.ISPRIMARY' [disabled]='!item.isedit'></td>
                    <td *ngIf='item.isedit'><input type="email"
                            class="form-control form-control-sm shadow-sm rounded-lg" [(ngModel)]='item.EMAILID'
                            [disabled]='item.isedit'></td>
                    <td *ngIf='!item.isedit'>{{item.EMAILID}}
                        <span>{{item.IsConfirmed}}</span>
                    </td>
                    <td *ngIf='item.isedit'><input type="text" class="form-control form-control-sm shadow-sm rounded-lg"
                            [(ngModel)]='item.MOBILENO' id="MOBILENO"
                            onkeypress='return (event.charCode >= 48 && event.charCode <= 57)' *ngIf='item.isedit' maxlength="10" minlength="1"></td>
                    <td *ngIf='!item.isedit'>{{item.MOBILENO}} </td>
                    <td *ngIf='item.isedit'><input type="text" class="form-control form-control-sm shadow-sm rounded-lg"
                            [(ngModel)]='item. secondary_mobileno'
                            onkeypress='return (event.charCode >= 48 && event.charCode <= 57)' *ngIf='item.isedit' maxlength="10" minlength="1"></td>
                    <td *ngIf='!item.isedit'>{{item.secondary_mobileno}} </td>
                    <td *ngIf='item.isedit' (click)='save(item,i)'><button class="btn btn-sm  btn-save">
                            &#8594;</button></td>
                    <td *ngIf='!item.isedit' class=" text-center" style="display: flex;">
                        <span data-bs-toggle="modal" data-bs-target="#myModal" src="" class="edit_icon"
                            (click)="edit(item)">
                            <i class="fas fa-pen pt-1 mt-1"></i></span>
                    </td>
                    <!-- <td *ngIf='!item.isedit' class=" text-center"><input type="image"
                                                    src="./../../../assets/images/sprites/edit.svg"
                                                    class="edit_icon text-center" (click)="edit(item)"> </td> -->
                </tr>
            </tbody>
        </table>
    </div>

</div>