<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0">List Of Books Allocated</h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="col-xl-12">
                                            <div class="table">
                                                <div *ngIf="returnDate.length">
                                                    <table id="patreggraph" datatable [dtOptions]="dtOptions"
                                                        class=" table-striped table-bordered mt-2 pt-2 table-sm small"
                                                        style="width:100%">
                                                        <thead class="top"
                                                            style="background-color: #624FD7; color:#ffffff;">
                                                            <tr>
                                                                <th> Book Tittle </th>
                                                                <th> BookId/ISBN </th>
                                                                <th> Department </th>
                                                                <th> Issue Date </th>
                                                                <th> Return Date </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr *ngFor="let item of returnDate">
                                                                <td>{{item.BOOK_NAME}}</td>
                                                                <td>{{item.BOOK_CODE}}</td>
                                                                <td>{{item.DEPARTMENT_NAME}}</td>
                                                                <td>{{item.BOOK_STD_DATE_OF_ISSUE | date:'dd/MM/yyyy'}}
                                                                </td>
                                                                <td>{{item.BOOK_STD_RETURN_DATE | date:'dd/MM/yyyy'}}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>

                                                <div class="text-center" *ngIf="!returnDate.length">
                                                    No Records to display
                                                </div>

                                               

                                                <div class="text-center pt-2">
                                                    <button type="button" (click)="ok()"
                                                    class="btn btn-sm  btn-save">OK</button>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>