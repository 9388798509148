<section>
    <div class="container-fluid bg-cta-2 ">
        <div class="row ">
            <div class="col-md-4 offset-0 offset-md-1 d-flex align-items-center">
                <h1 class="txt-cta">We have great<br> achivements to show</h1>
            </div>
            <div class="col-md-6 ">
                <ul class="d-flex justify-content-evenly border-0 bg-transparent text-center ps-0">
                    <li class="list-group-item border-0 bg-transparent">
                        <span class="border-box"></span>
                        <h4 class="text-white">{{getCount('STUDENTS')}}+</h4>
                        <img src="assets/img/icons/student.png" width="70px" width="70px" class="img-fluid" alt="">
                        <h6 class="title mt-3">Students</h6>

                    </li>
                    <li class="list-group-item border-0 bg-transparent">
                        <span class="border-box"></span>
                        <h4 class="text-white">{{getCount('COURSES')}}+</h4>
                        <img src="assets/img/icons/courses.png" width="70px" width="70px" class="img-fluid" alt="">
                        <h6 class="title  mt-3">Courses</h6>

                    </li>
                    <li class="list-group-item border-0 bg-transparent">
                        <span class="border-box"></span>
                        <h4 class="text-white">{{getCount('TUTORS')}}+</h4>
                        <img src="assets/img/icons/Trainer.png" width="70px" width="70px" class="img-fluid" alt="">
                        <h6 class="title mt-3">Trainers</h6>

                    </li>
                </ul>
            </div>
        </div>
    </div>
</section>
<!-- map -->
<section>
    <div class="container-fluid">
        <div class="row">
            <div class="col-xl-12 p-0">
                <div class="ratio ratio-21x9">
                    <!-- <iframe src="https://www.pragatiutrack.com/maps/clustering/" title="Map" -->
                        {{googleURL}}
                    <iframe [src]="googleURL" title="Map"
                        allowfullscreen></iframe>
                </div>
            </div>
        </div>
    </div>
</section>