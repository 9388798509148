<!-- <div class="modal" id="myModal_education" data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog ">
        <div class="modal-content">

           
            <div class="modal-header py-2">
                <h4 *ngIf="!isEdit" class="modal-title"> Add Education</h4>
                <h4 *ngIf="isEdit" class="modal-title"> Edit Education</h4>
                <button type="button" (click)="close()" class="close" data-bs-dismiss="modal">&times;</button>
            </div> -->


<div class="custom_container">
    <form class="well form-horizontal" [formGroup]="myForm" (ngSubmit)="onSubmit(myForm)">
        <fieldset>
            <div class="form-group row pt-4">
                <label class="col-md-5 control-label">Education Type <span class="text-danger">*</span></label>
                <div class="col-md-7 inputGroupContainer">
                    <div class="input-group">
                        <select formControlName='EDUCATIONTYPE' class="form-select form-select-sm shadow-sm rounded-lg">
                            <option value="" selected>Select</option>
                            <option *ngFor='let item of educationTypes' [value]='item.DICTIONARYNAME'>
                                {{item.DICTIONARYNAME}}</option>
                        </select>
                    </div>
                    <div *ngIf="myForm.get('EDUCATIONTYPE').touched">
                        <span class="text-danger" *ngIf="myForm.get('EDUCATIONTYPE').hasError('required')">
                            This field is required.
                        </span>
                    </div>

                </div>
            </div>
            <!-- <div class="form-group row pt-4">
                            <label class="col-md-4 control-label"> Education Mode <span
                                    class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <select formControlName='EDUCATIONMODE' (change)='modeChange($event.target.value)'
                                        class="form-control form-control-sm shadow-sm rounded-lg">
                                        <option value="" selected>Select</option>
                                        <option *ngFor='let item of educationModes' [value]='item.DICTIONARYNAME'>{{item.DICTIONARYNAME}}</option>
                                    </select>
                                </div>
                               
                            </div>
                        </div> -->
            <div class="form-group row pt-4">
                <label class="col-md-5 control-label">Education Name <span class="text-danger">*</span>
                </label>
                <div class="col-md-7 inputGroupContainer">
                    <div class="input-group">
                        <select formControlName='EDUCATIONNAME' class="form-select form-select-sm shadow-sm rounded-lg">
                            <option value="" selected>Select</option>
                            <option *ngFor='let item of EducationName' [value]='item.DICTIONARYNAME'>
                                {{item.DICTIONARYNAME}}</option>

                        </select>
                    </div>
                    <div *ngIf="myForm.get('EDUCATIONNAME').touched">
                        <span class="text-danger" *ngIf="myForm.get('EDUCATIONNAME').hasError('required')">
                            This field is required.
                        </span>
                    </div>

                </div>
            </div>
            <!-- <div class="form-group row pt-4">
                <label class="col-md-5 control-label">Specialization
                    <span class="text-danger">*</span>
                </label>
                <div class="col-md-7 inputGroupContainer">
                    <div class="input-group">
                        <select formControlName='SPECIALIZATION'
                            class="form-select form-select-sm shadow-sm rounded-lg">
                            <option value="" selected>Select</option>
                            <option *ngFor='let item of Specialization' [value]='item.DICTIONARYNAME'>
                                {{item.DICTIONARYNAME}}</option>
                        </select>
                    </div>
                    <div *ngIf="myForm.get('SPECIALIZATION').touched">
                        <span class="text-danger" *ngIf="myForm.get('SPECIALIZATION').hasError('required')">
                            This field is required.
                        </span>
                    </div>

                </div>

            </div> -->

            <div class="form-group row pt-4">
                <label class="col-md-5 control-label"> Specialization
                     <span class="text-danger">*</span> 
                </label>
                <div class="col-md-7 inputGroupContainer">
                    <div class="input-group">
                        <input  type="text" formControlName='SPECIALIZATION'
                            class="form-control form-control-sm shadow-sm rounded-lg" >


                    </div>
                    <div *ngIf="myForm.get('SPECIALIZATION').touched">
                        <span class="text-danger" *ngIf="myForm.get('SPECIALIZATION').hasError('required')">
                            This field is required.
                        </span>
                    </div>

                </div>
            </div>
            <!-- <div class="form-group row pt-4">
                <label class="col-md-5 control-label"> University/Institute
                    <span class="text-danger">*</span>
                </label>
                <div class="col-md-7 inputGroupContainer">
                    <div class="input-group">
                        <select formControlName='UNIVERSITYINSTITUTE'
                            class="form-select form-select-sm shadow-sm rounded-lg">
                            <option value="" selected>Select</option>
                            <option *ngFor='let item of Institutes' [value]='item.DICTIONARYNAME'>
                                {{item.DICTIONARYNAME}}</option>
                        </select>
                    </div>
                    <div *ngIf="myForm.get('UNIVERSITYINSTITUTE').touched">
                        <span class="text-danger" *ngIf="myForm.get('UNIVERSITYINSTITUTE').hasError('required')">
                            This field is required.
                        </span>
                    </div>

                </div>

            </div> -->

            <div class="form-group row pt-4">
                <label class="col-md-5 control-label"> University/Institute
                     <span class="text-danger">*</span> 
                </label>
                <div class="col-md-7 inputGroupContainer">
                    <div class="input-group">
                        <input  type="text" formControlName='UNIVERSITYINSTITUTE'
                            class="form-control form-control-sm shadow-sm rounded-lg" >


                    </div>
                    <div *ngIf="myForm.get('UNIVERSITYINSTITUTE').touched">
                        <span class="text-danger" *ngIf="myForm.get('UNIVERSITYINSTITUTE').hasError('required')">
                            This field is required.
                        </span>
                    </div>

                </div>
            </div>
            <div class="form-group row pt-4">
                <label class="col-md-5 control-label line_2 rem_1">
                    Year Of Passing
                    <span class="text-danger">*</span>
                </label>
                <div class="col-md-7 inputGroupContainer">
                    <div class="input-group">
                        <select formControlName='YEAROFPASSING' class="form-select form-select-sm shadow-sm rounded-lg">
                            <option value="" selected>Select</option>
                            <option *ngFor='let year of yearList' [value]='year.id'>{{year.name}}</option>
                        </select>
                    </div>
                    <div *ngIf="myForm.get('YEAROFPASSING').touched">
                        <span class="text-danger" *ngIf="myForm.get('YEAROFPASSING').hasError('required')">
                            This field is required.
                        </span>
                    </div>

                </div>


            </div>



        </fieldset>
    </form>

</div>
<!-- <div class="modal-footer">
                <button type="button" *ngIf="!isEdit" class="btn btn-sm btn-save" (click)="onSubmit(myForm)"
                    [disabled]="myForm.invalid">Save</button>
                <button type="button" *ngIf="isEdit" class="btn btn-sm btn-save" (click)="onSubmit(myForm)"
                    [disabled]="myForm.invalid">Update</button>
                <button type="button" class="btn btn-sm btn-danger" id="md_close_education" (click)="close()"
                    data-bs-dismiss="modal">Close</button>
            </div> -->
<div class="text-center pt-4 d-none">
    <button type="button" class="btn btn-sm  btn-success" (click)="onSubmit(myForm)">{{btnTitle}}</button> &nbsp;
    <button type="button" class="btn btn-sm btn-danger"
                    (click)="close()">Clear</button> 
</div>
<!-- </div>
    </div>
</div> -->

<div class="mt-5 d-none">
    <!-- <div class="text-right">
        <button type="button" class="btn btn-sm btn-primary" (click)="add()" data-bs-toggle="modal"
            data-bs-target="#myModal_education">Add</button>

    </div> -->
    <table *ngIf='table.length' class="table table-striped table-responsive-md btn-table table-bordered text-dark">
        <thead class="top" style="background-color: #624FD7; color:#ffffff;">
            <tr>
                <th>Education Type</th>
                <!-- <th>Education Mode</th> -->
                <th>Education Name</th>
                <th>Specialization</th>
                <th>University Institute</th>
                <th>Year Of Passing</th>
                <th>Operation</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor='let item of table;let i=index'>
                <td>{{item.EDUCATIONTYPE}}</td>
                <!-- <td>{{item.EDUCATIONMODE}}</td> -->
                <td>{{item.EDUCATIONNAME}}</td>
                <td>{{item.SPECIALIZATION}}</td>
                <td>{{item.UNIVERSITYINSTITUTE}}</td>
                <td>{{item.YEAROFPASSING}}</td>
                <td style="display: flex;"> <span class="edit_icon" style="cursor: pointer;" (click)="edit(item,i)">
                        <i class="fas fa-pen pt-1 mt-1"></i></span>
            </tr>
        </tbody>
    </table>
    <!-- <p class="text-center" *ngIf='!table.length'>No Records</p> -->
</div>
<!-- 
<div class="text-center pt-4" *ngIf="table.length>0">
    <button type="button" class="btn btn-sm  btn-success"
        (click)='save()'>Save</button> &nbsp;
     <button type="button" class="btn btn-sm btn-danger"
        (click)="close1()">Close</button>
</div>
  -->