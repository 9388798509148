<div>
    <div class="d-flex flex-row float-start mb-3">
        <div class=" me-4">
            <!-- <img class="img-profile" src="./img/profile-tutor/img_avatar.png"> -->
            <img class="img-profile rounded-circle" style="width:150px; height:150px" [src]="userDetails.userfrimages">
        </div>
        <div class="mt-3">
            <h4 class=" profile_name">{{userDetails.name}}</h4>
            <div>
                <span class="fa fa-star" [ngClass]="{checked:userDetails.rating>0}"></span>
                <span class="fa fa-star" [ngClass]="{checked:userDetails.rating>1}"></span>
                <span class="fa fa-star" [ngClass]="{checked:userDetails.rating>2}"></span>
                <span class="fa fa-star" [ngClass]="{checked:userDetails.rating>3}"></span>
                <span class="fa fa-star" [ngClass]="{checked:userDetails.rating>4}"></span>
                <span class="badge badge_clr ms-2">{{userDetails.rating}}</span>
            </div>
            <!-- <div style="display: table;" class="pt-3">
                <div style="display: table-cell;" class="border-end border-secondary pe-3 ">
                    <span>0</span>
                    <p class="mb-0">Followers</p>
                </div>
                <div style="display: table-cell;" class="ps-3">
                    <span>0</span>
                    <p class="mb-0">Following</p>
                </div>
            </div> -->


        </div>
    </div>
    <!-- <div class=" float-end mt-3">
        <button class="btn btn-sm btn_fallow" [routerLink]="['/signup']">Become a Tutor</button>
    </div> -->
</div>