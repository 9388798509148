<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">

                    <div class="container-fluid">

                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0">My Payment</h5>
                                    </div>
                                    <div class="card-body">

                                        <table id="patreggraph" datatable [dtOptions]="dtOptions"
                                            [dtTrigger]="dtTrigger"
                                            class="table table-striped table-bordered mt-2 pt-2 table-sm small display"
                                            style="width:100%">
                                            <thead class="top" style="background-color: #624fd7; color:#ffffff;">
                                                <tr>
                                                    <th> Course Name </th>
                                                    <th> Tutor Name </th>
                                                    <th> Amount </th>
                                                    <th> Start Date </th>
                                                    <th> End Date </th>
                                                    <th> Start Time </th>
                                                    <th> End Time </th>
                                                    <th> Payment </th>
                                                    <!-- <th> Action </th> -->
                                                    <th> Invoice </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let item of myCourseList">
                                                    <td>{{item.COURSE_NAME}}</td>
                                                    <td>{{item.TNT_NAME}}</td>
                                                    <td>{{item.COURSESHD_AMOUNT | currency:'INR'}}</td>
                                                    <td>{{item.COURSESHD_STARTDATE | date:'dd-MM-yyyy'}}</td>
                                                    <td>{{item.COURSESHD_ENDDATE | date:'dd-MM-yyyy'}}</td>
                                                    <td>{{item.COURSESHD_STARTTIME | date:'hh:mm a'}}</td>
                                                    <td>{{item.COURSESHD_ENDTIME | date:'hh:mm a'}}</td>
                                                    <td>
                                                        <button type="button" class="btn btn-success btn-sm"
                                                        *ngIf="item.Installments.length>0"
                                                            (click)="InstallmentList=item.Installments;displayModel();showInstallmentModel=true;">Installments
                                                        </button>
                                                    </td>
                                                    <!-- <td>
                                                        <button type="button" class="btn btn-sm btn-success"
                                                            (click)="refund(item.COURSESHD_STARTDATE)"
                                                            [disabled]="isCheck(item.COURSESHD_STARTDATE)"> Refund
                                                        </button>
                                                    </td> -->
                                                    <td>
                                                        <button type="button" class="btn btn-sm btn-success"
                                                            (click)="showInvoice(item.COURSESHD_ID)" > Invoice
                                                        </button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<input type="button" class="d-none" id="btnShowModel" data-bs-target="#InstallmentDetails" data-bs-toggle="modal">
<app-paid-installments *ngIf="showInstallmentModel" [InstallmentList]="InstallmentList" (modelCloseEvent)="showInstallmentModel=false;">
</app-paid-installments>
