<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0 ">Enroll Students </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="form-group">
                                            <div class="row">
                                                <div class="col-xl-4 mb-4">
                                                    <div class="form">
                                                        <label class="form-label line_2 rem_1">
                                                            Course Name <span class="text-danger">*</span></label>
                                                        <select [(ngModel)]='courceId ' [disabled]="isParam" id="sel1" 
                                                        (change)="courceChange()"
                                                            class="form-control form-control-sm shadow-sm rounded-lg ">
                                                            <option value="" [selected]="true">select</option>
                                                            <option *ngFor="let course of cources"
                                                                [value]='course.COURSE_ID'>
                                                                {{course.COURSE_NAME}}
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-xl-4 mb-4">
                                                    <div class="form">
                                                        <label class="form-label line_2 rem_1" for="sel1">Batch Name
                                                            <span class="text-danger">*</span> </label>
                                                        <select [(ngModel)]='schedulId ' [disabled]="isParam" id="sel1"
                                                            class="form-control form-control-sm shadow-sm rounded-lg"
                                                            (change)="schedulChange()">
                                                            <option value="" [selected]="true">select</option>
                                                            <option *ngFor="let schedul of scheduls"
                                                                [value]='schedul.COURSESHD_ID'>
                                                                {{schedul.COURSESHD_NAME}}</option>

                                                        </select>

                                                    </div>
                                                </div>
                                                <div class="col-xl-3 mb-4">
                                                    <div class="form">
                                                        <label class="form-label line_2 rem_1" for="sel1">Year Of Registration  
                                                            <span class="text-danger">*</span> </label>
                                                        <select [(ngModel)]='yearId ' [disabled]="isParam" id="sel1"
                                                            class="form-control form-control-sm shadow-sm rounded-lg"
                                                            (change)="yearChange()">
                                                            <option value="" [selected]="true">select</option>
                                                            <option *ngFor="let year of years" [value]='year.YearId'>
                                                                {{year.Year}}</option>

                                                        </select>

                                                    </div>
                                                </div>
                                            </div>


                                        </div>

                                        <div class="pt-2">
                                            <div *ngIf="table.length">
                                                <table id="patreggraph" datatable
                                                    class="table table-striped table-bordered mt-2 pt-2 table-sm small"
                                                    style="width:100%">
                                                    <thead class="top"
                                                        style="background-color: #624FD7; color:#ffffff;">
                                                        <tr>
                                                            <th>
                                                            </th>
                                                            <th> Student Name</th>
                                                            <th>Email Id</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let item of table">
                                                            <td><input [(ngModel)]="item.Paymentstatus" type="checkbox">
                                                            </td>
                                                            <td>{{item.Studentname}}</td>
                                                            <td>{{item.UserName}}</td>
                                                    </tbody>
                                                </table>
                                                <div class="text-center">
                                                    <button type="button" class="btn btn-sm  btn-save"
                                                        (click)="enroll()">Submit</button>
                                                </div>
                                            </div>
                                            <div class="text-center" *ngIf="!table.length">No Records to display</div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>