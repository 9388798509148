import { PrivacyPolicyComponent } from './components/organization-details/privacy-policy/privacy-policy.component';
import { TermsConditionsComponent } from './components/organization-details/terms-conditions/terms-conditions.component';
import { RefundPolicyComponent } from './components/organization-details/refund-policy/refund-policy.component';
import { ContactUs1Component } from './components/organization-details/contact-us/contact-us1.component';
import { AboutUsComponent } from './components/organization-details/about-us/about-us.component';
import { ERPComponent } from './erp/erp.component';
import { TutorPaymentsComponent } from './components/tutor/tutor-payments/tutor-payments.component';
import { EnrolledStudentsComponent } from './components/tutor/enrolled-students/enrolled-students.component';
import { MyReviewComponent } from './components/tutor/my-review/my-review.component';
import { AutocompleteDemoComponent } from './components/tutor/autocomplete-demo/autocomplete-demo.component';
import { CoursesResolver } from './resolvers/coursesResolver.resolver';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { LoginComponent } from './pages/login/login.component';
import { MaincontentComponent } from './maincontent/maincontent.component';
import { PolsComponent } from './pages/pols/pols.component';
import { TakepolsComponent } from './pages/takepols/takepols.component';
import { SurveysComponent } from './pages/surveys/surveys.component';
import { TakesurveysComponent } from './pages/takesurveys/takesurveys.component';
import { SubmitassignmentsComponent } from './pages/submitassignments/submitassignments.component';
import { JoinconferenceComponent } from './pages/joinconference/joinconference.component';
import { LearningmaterialComponent } from './pages/learningmaterial/learningmaterial.component';
import { PostassessmentComponent } from './pages/postassessment/postassessment.component';
import { MyresultsComponent } from './pages/myresults/myresults.component';
import { StartexamComponent } from './pages/startexam/startexam.component';
import { TimetrackerComponent } from './pages/timetracker/timetracker.component';
import { AssignmentsComponent } from './pages/assignments/assignments.component';
import { AddassignmentComponent } from './pages/addassignment/addassignment.component';
import { EvaluateassignmentsComponent } from './pages/evaluateassignments/evaluateassignments.component';
import { EvaluateassessmentComponent } from './pages/evaluateassessment/evaluateassessment.component';
import { ExamresultComponent } from './pages/examresult/examresult.component';
import { CourcesessionsComponent } from './pages/courcesessions/courcesessions.component';
import { CreatepolComponent } from './pages/createpol/createpol.component';
import { CreatesurveyComponent } from './pages/createsurvey/createsurvey.component';
import { AddpolComponent } from './pages/addpol/addpol.component';
import { AddsurveyComponent } from './pages/addsurvey/addsurvey.component';
import { AddsurveyquestionComponent } from './pages/addsurveyquestion/addsurveyquestion.component';
import { CreateFAQsComponent } from './pages/create-faqs/create-faqs.component';
import { FeedbackquestionaireComponent } from './pages/feedbackquestionaire/feedbackquestionaire.component'
import { FeedbackResultsComponent } from './pages/feedback-results/feedback-results.component'
import { SmtpComponent } from './pages/smtp/smtp.component';
import { TaskmanagerComponent } from './pages/taskmanager/taskmanager.component';
import { NewsComponent } from './pages/news/news.component';
import { CourceCategoryComponent } from './pages/cource-category/cource-category.component';
import { CourceTypeComponent } from './pages/cource-type/cource-type.component';
import { CourcesComponent } from './pages/cources/cources.component';
import { ChaptersComponent } from './pages/chapters/chapters.component';
import { EnrollComponent } from './pages/enroll/enroll.component';
import { RegulationsComponent } from './pages/regulations/regulations.component';
import { AttainmentlevelComponent } from './pages/attainmentlevel/attainmentlevel.component';
import { RoomsComponent } from './pages/rooms/rooms.component';
import { AssignMaterialsComponent } from './pages/assign-materials/assign-materials.component';
import { ContentManagerComponent } from './pages/content-manager/content-manager.component';
import { AssessmentQuestionnaireComponent } from './pages/assessment-questionnaire/assessment-questionnaire.component';
import { ScheduleAssessmentComponent } from './pages/schedule-assessment/schedule-assessment.component';
import { MasterAssessmentComponent } from './pages/master-assessment/master-assessment.component';
import { AttendanceComponent } from './pges/attendance/attendance.component';
import { CourceAssignTrainerComponent } from './pges/cource-assign-trainer/cource-assign-trainer.component';
import { CourceScheduleComponent } from './pges/cource-schedule/cource-schedule.component';
import { AddCourceScheduleComponent } from './pges/add-cource-schedule/add-cource-schedule.component';
import { CourceAssignUsersComponent } from './pges/cource-assign-users/cource-assign-users.component';
import { MailBoxComponent } from './pges/mail-box/mail-box.component';
import { ForumComponent } from './pges/forum/forum.component';
import { ForumTopicsComponent } from './pges/forum-topics/forum-topics.component';
import { AddAsseementQuestioniareComponent } from './pages/add-asseement-questioniare/add-asseement-questioniare.component';
import { AddMasterAssessmentComponent } from './pages/add-master-assessment/add-master-assessment.component';
import { CourseshAssignUsersComponent } from './pages/coursesh-assign-users/coursesh-assign-users.component';
import { CourseshAssigntrainersComponent } from './pages/coursesh-assigntrainers/coursesh-assigntrainers.component';
import { CourseProgramOutcomeComponent } from './pages/course-program-outcome/course-program-outcome.component';
import { RoleComponent } from './pages/role/role.component';
import { TaskInRolesComponent } from './pages/task-in-roles/task-in-roles.component';
import { MyPostsComponent } from './pages/my-posts/my-posts.component';
import { AllBlogsComponent } from './pages/all-blogs/all-blogs.component';
import { BlogsComponent } from './pages/blogs/blogs.component';
import { ViewPostComponent } from './pages/view-post/view-post.component';
import { Resolver } from './services/resolve.service';
import { UserRegistrationComponent } from './pages/user-registration/user-registration.component';
import { AddUserRegistrationComponent } from './pages/add-user-registration/add-user-registration.component';
import { EditUserRegistrationComponent } from './pages/edit-user-registration/edit-user-registration.component';
import { TenantRegistrationComponent } from './pages/tenant-registration/tenant-registration.component';
import { CreateTenantRegistrationComponent } from './pages/create-tenant-registration/create-tenant-registration.component';
import { ContentAuthoringComponent } from './pages/content-authoring/content-authoring.component';
import { BackupManagerComponent } from './pages/backup-manager/backup-manager.component';
import { OfflinePaymentComponent } from './pages/offline-payment/offline-payment.component';
import { BillingInformationComponent } from './pages/billing-information/billing-information.component';
import { SubjectComponent } from './pages/subject/subject.component';
import { PollResultsComponent } from './pages/poll-results/poll-results.component';
import { EventRequestComponent } from './pages/event-request/event-request.component';
import { DataDictionaryComponent } from './pages/data-dictionary/data-dictionary.component';
import { SubscriptionTaskComponent } from './pages/subscription-task/subscription-task.component';
import { LocationsComponent } from './pages/locations/locations.component';
import { DetailedAssessmentComponent } from './pages/reports/detailed-assessment/detailed-assessment.component';
import { StudentInformationComponent } from './pages/reports/student-information/student-information.component';
import { CourseTrainersDetailsComponent } from './pages/reports/course-trainers-details/course-trainers-details.component';
import { CourseInformationReportComponent } from './pages/reports/course-information-report/course-information-report.component';
import { OnlineExamreportComponent } from './pages/reports/online-examreport/online-examreport.component';
import { OnlineCoursesReportComponent } from './pages/reports/online-courses-report/online-courses-report.component';
import { OnlineCoursesDetailsReportComponent } from './pages/reports/online-courses-details-report/online-courses-details-report.component';
import { UserReportComponent } from './pages/reports/user-report/user-report.component';
import { CoursewiseperformanceReportComponent } from './pages/reports/coursewiseperformance-report/coursewiseperformance-report.component';
import { AssessmentResultComponent } from './pages/assessment-result/assessment-result.component';
import { AttendanceDetailsReportComponent } from './pages/reports/attendance-details-report/attendance-details-report.component';
import { TrainingCalendarComponent } from './pages/training-calendar/training-calendar.component';
import { UploadTemplateComponent } from './pages/upload-template/upload-template.component';
import { MainComponent } from './pages/iscribe/main/main.component';
import { StudentCgpaComponent } from './pages/reports/student-cgpa/student-cgpa.component'
import { StudentSGPAReportComponent } from './pages/reports/student-sgpa-report/student-sgpa-report.component'
import { VisualEditorComponent } from './pages/visual-editor/visual-editor.component';
import { FolderComponent } from './pages/folder/folder.component';
import { SemesterComponent } from './pages/semester/semester.component';
import { StudentAdmissionComponent } from './pages/student-admission/student-admission.component';
import { AddStudentAdmissionComponent } from './pages/add-student-admission/add-student-admission.component';
import { AcademicYearComponent } from './pages/academic-year/academic-year.component';
import { NewTeacherComponent } from './pages/new-teacher/new-teacher.component';
import { AddNewTeacherComponent } from './pages/add-new-teacher/add-new-teacher.component';
import { FeesTypeComponent } from './pages/fees-type/fees-type.component';
import { FeeReceivableComponent } from './pages/fee-receivable/fee-receivable.component';
import { FeeDescriptionComponent } from './pages/fee-description/fee-description.component';
import { EditFeesComponent } from './pages/edit-fees/edit-fees.component';
import { CurriculumComponent } from './pages/curriculum/curriculum.component';
import { StudentFeereceivableComponent } from './pages/student-feereceivable/student-feereceivable.component';
import { StudentRegistrationApprovalComponent } from './pages/student-registration-approval/student-registration-approval.component';
import { ContentResourcesComponent } from './pages/content-resources/content-resources.component';
import { ContentClassComponent } from './pages/content-class/content-class.component';
import { ContentChapterComponent } from './pages/content-chapter/content-chapter.component';
import { ContentSubjectComponent } from './pages/content-subject/content-subject.component';
import { ContentRepoAddComponent } from './pages/content-repo-add/content-repo-add.component';
import { ContentRepoComponent } from './pages/content-repo/content-repo.component';
import { BookAllocationReportComponent } from './pages/book-allocation-report/book-allocation-report.component';
import { BookAllocationViewReportComponent } from './pages/book-allocation-view-report/book-allocation-view-report.component';
import { StudentsReportComponent } from './pages/students-report/students-report.component';
import { FetchAssignedBooksComponent } from './pages/fetch-assigned-books/fetch-assigned-books.component';
import { ReturnDateComponent } from './pages/return-date/return-date.component';
import { UpdateReturnDateComponent } from './pages/update-return-date/update-return-date.component';
import { LibraryManagementSystemComponent } from './pages/library-management-system/library-management-system.component';
import { LibraryBooksAllocationComponent } from './pages/library-books-allocation/library-books-allocation.component';
import { ListOfBooksComponent } from './pages/list-of-books/list-of-books.component';
import { DepartmentComponent } from './pages/department/department.component';
import { HolidayNotificationComponent } from './pages/holiday-notification/holiday-notification.component';
import { ViewNewsComponent } from './pages/view-news/view-news.component';
import { ViewDiscussionComponent } from './pages/view-discussion/view-discussion.component';
import { SamvaadMeetingsComponent } from './pages/samvaad-meetings/samvaad-meetings.component';
import { CourseComponent } from './pages/course/course.component';
import { ViewCourseComponent } from './pages/view-course/view-course.component';
import { TutorHomeComponent } from './components/tutor/tutor-home/tutor-home.component';
import { TutorLoginComponent } from './components/tutor/tutor-login/tutor-login.component';
import { TutorSignupComponent } from './components/tutor/tutor-signup/tutor-signup.component';
import { AllCategoryCoursesComponent } from './components/tutor/all-category-courses/all-category-courses.component';
import { ChangePasswordComponent } from './components/tutor/change-password/change-password.component';
import { ViewCourseDetailsComponent } from './components/tutor/view-course-details/view-course-details.component';
import { StudentHomeComponent } from './components/tutor/student-home/student-home.component';
import { ViewAllDetailsComponent } from './pages/view-all-details/view-all-details.component';
import { CoursesComponent } from './pages/courses/courses.component';
import { MyCoursesComponent } from './pages/my-courses/my-courses.component';
import { MyTutorsComponent } from './pages/my-tutors/my-tutors.component';
import { MyPaymentsComponent } from './pages/my-payments/my-payments.component';
import { MyLanguageComponent } from './pages/my-language/my-language.component';
import { LevelofLearnerComponent } from './pages/levelof-learner/levelof-learner.component';
import { ViewQrcodeComponent } from './pages/view-qrcode/view-qrcode.component';
import { TrainerDashboardComponent } from './pages/trainer-dashboard/trainer-dashboard.component';
import { ViewProfileComponent } from './components/tutor/view-profile/view-profile.component';
import { AdminDashboardComponent } from './pages/admin-dashboard/admin-dashboard.component';
import { ConfigureImagesComponent } from './pages/configure-images/configure-images.component';
import { ApprovalDomainComponent } from './pages/approval-domain/approval-domain.component';
import { StudentSignupComponent } from './components/tutor/student-signup/student-signup.component';
import { ApproveComponent } from './pages/approve/approve.component';
import { InvoiceComponent } from './components/tutor/invoice/invoice.component';
import { WellcomeRegistraionComponent } from './pages/wellcome-registraion/wellcome-registraion.component';
import { CompanyRegistrationComponent } from './pages/company-registration/company-registration.component';
import { RecordingScheduleComponent } from './pages/recording-schedule/recording-schedule.component';
import { CourseReportComponent } from './pages/course-report/course-report.component';
import { TrainerReportComponent } from './pages/trainer-report/trainer-report.component';
import { TraineeReportComponent } from './pages/trainee-report/trainee-report.component';
import { FeeReportComponent } from './pages/fee-report/fee-report.component';
import { NotificationComponent } from './pages/notification/notification.component';
import { LearningResourcesComponent } from './pages/learning-resources/learning-resources.component';
const routes: Routes = [
  {
    path: 'HOME',
    component: HomeComponent,

    children: [
      {
        path: 'dashboard',
        component: MaincontentComponent
      },
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full'
      },
      {
        path: 'poll',
        component: PolsComponent,
      },
      {
        path: 'takepoll',
        component: TakepolsComponent
      },
      {
        path: 'surveys',
        component: SurveysComponent
      },
      {
        path: 'takesurvey',
        component: TakesurveysComponent
      }, {
        path: 'submitassignment',
        component: SubmitassignmentsComponent
      }, {
        path: 'joinconference',
        component: JoinconferenceComponent
      }, {
        path: 'learningmaterial',
        component: LearningmaterialComponent
      }, {
        path: 'postassessment',
        component: PostassessmentComponent
      },
      {
        path: 'myresults',
        component: MyresultsComponent
      },
      {
        path: 'takeExam',
        component: StartexamComponent
      },
      {
        path: 'timeTracker',
        component: TimetrackerComponent
      },
      {
        path: 'assignments',
        component: AssignmentsComponent
      },
      {
        path: 'addAssignments',
        component: AddassignmentComponent
      },
      {
        path: 'evaluateAssignments',
        component: EvaluateassignmentsComponent
      },
      {
        path: 'evaluateAssessment',
        component: EvaluateassessmentComponent
      },
      {
        path: 'examResult',
        component: ExamresultComponent
      },
      {
        path: "courseSessions",
        component: CourcesessionsComponent
      },
      {
        path: "createPolls",
        component: CreatepolComponent
      },
      {
        path: "createSurvey",
        component: CreatesurveyComponent
      },
      {
        path: 'addPoll',
        component: AddpolComponent
      },
      {
        path: 'addSurvey',
        component: AddsurveyComponent
      },
      {
        path: 'surveyQuestion',
        component: AddsurveyquestionComponent
      },
      {
        path: "createFAQs",
        component: CreateFAQsComponent

      },
      {
        path: 'feedbackQuestionnaire',
        component: FeedbackquestionaireComponent
      },
      {
        path: 'feedbackResults',
        component: FeedbackResultsComponent
      },
      {
        path: 'smtp',
        component: SmtpComponent
      },
      {
        path: 'taskmanager',
        component: TaskmanagerComponent
      },
      {
        path: 'news',
        component: NewsComponent
      }, {
        path: 'courseCategory',
        component: CourceCategoryComponent
      },
      {
        path: 'courseType',
        component: CourceTypeComponent
      },
      {
        path: "courses",
        component: CourcesComponent
      },
      {
        path: 'course-assignTrainer',
        component: CourceAssignTrainerComponent,
      },
      {
        path: 'course-programOutcome',
        component: CourseProgramOutcomeComponent
      },
      {
        path: 'chapters',
        component: ChaptersComponent
      },
      {
        path: 'enroll',
        component: EnrollComponent
      },
      {
        path: "regulations",
        component: RegulationsComponent
      },
      {
        path: 'attainmentlevel',
        component: AttainmentlevelComponent
      }, {
        path: 'rooms',
        component: RoomsComponent
      }, {
        path: 'configurematerials',
        component: AssignMaterialsComponent
      },
      {
        path: 'contentmanager',
        component: ContentManagerComponent
      },
      {
        path: 'assessmentQuestionnaire',
        component: AssessmentQuestionnaireComponent
      },
      {
        path: 'asssessemnt/:id',
        component: AddAsseementQuestioniareComponent
      },
      {
        path: 'scheduleAssessment',
        component: ScheduleAssessmentComponent
      },
      {
        path: 'masterAssessment',
        component: MasterAssessmentComponent
      },
      {
        path: 'masterAssessment/:id',
        component: AddMasterAssessmentComponent
      },
      {
        path: 'attendance',
        component: AttendanceComponent
      },

      {
        path: 'courseSchedule',
        component: CourceScheduleComponent
      },
      {
        path: 'courseSchedule/:id',
        component: AddCourceScheduleComponent
      },
      {
        path: 'courseSchedule-AssignUser',
        component: CourseshAssignUsersComponent
      },
      {
        path: 'courseSchedule-AssignTrainer',
        component: CourseshAssigntrainersComponent
      },
      {
        path: 'mailBox',
        component: MailBoxComponent
      },
      {
        path: 'forums',
        component: ForumComponent
      },
      {
        path: 'forumTopics',
        component: ForumTopicsComponent
      }, {
        path: 'role',
        component: RoleComponent
      },
      {
        path: 'taskInRole',
        component: TaskInRolesComponent
      },
      {
        path: 'myPosts',
        component: MyPostsComponent
      },
      {
        path: 'allBlogs',
        component: AllBlogsComponent
      },
      {
        path: 'blogs',
        component: BlogsComponent
      },
      {
        path: 'viewPost',
        component: ViewPostComponent
      },
      {
        path: 'usersRegistrationList',
        component: UserRegistrationComponent
      },
      {
        path: 'userRegistration',
        component: EditUserRegistrationComponent
      },
      {
        path: 'addUserRegistration',
        component: AddUserRegistrationComponent
      },
      // {
      //   path: 'editUserRegistration',
      //   component: EditUserRegistrationComponent
      // },
      {
        path: 'tenantRegistration',
        component: TenantRegistrationComponent
      },
      {
        path: 'tenantRegistration/:id',
        component: CreateTenantRegistrationComponent
      },
      {
        path: 'contentAuthoring',
        component: ContentAuthoringComponent
      },
      {
        path: 'backupManager',
        component: BackupManagerComponent
      },
      {
        path: 'offlinePayment',
        component: OfflinePaymentComponent
      },
      {
        path: 'billingInformation',
        component: BillingInformationComponent
      },
      {
        path: 'subject',
        component: SubjectComponent
      },
      {
        path: 'pollResults',
        component: PollResultsComponent
      },
      {
        path: 'eventRequest',
        component: EventRequestComponent
      },
      {
        path: 'meetings',
        component: SamvaadMeetingsComponent
      },
      {
        path: 'dataDictionary',
        component: DataDictionaryComponent
      },
      {
        path: 'subscriptionTasks',
        component: SubscriptionTaskComponent
      },
      {
        path: 'locations',
        component: LocationsComponent
      },
      {
        path: 'DetailedAssessment',
        component: DetailedAssessmentComponent
      },
      {
        path: 'studentInformation',
        component: StudentInformationComponent
      },
      {
        path: 'courseTrainersReport',
        component: CourseTrainersDetailsComponent
      },
      {
        path: 'courseInformationReport',
        component: CourseInformationReportComponent
      },
      {
        path: 'onlineExamReport',
        component: OnlineExamreportComponent
      },
      {
        path: 'onlineCoursesReport',
        component: OnlineCoursesReportComponent
      },
      {
        path: 'onlineCoursesDeatilsReport',
        component: OnlineCoursesDetailsReportComponent
      },
      {
        path: 'userReport',
        component: UserReportComponent
      },
      {
        path: 'courseWisePerformance',
        component: CoursewiseperformanceReportComponent
      },
      {
        path: 'assessmentResult',
        component: AssessmentResultComponent
      },
      {
        path: "attendanceReport",
        component: AttendanceDetailsReportComponent
      },
      {
        path: 'trainingCalendar',
        component: TrainingCalendarComponent
      },
      {
        path: 'uploadTemplate',
        component: UploadTemplateComponent
      },
      {
        path: 'iscribe',
        component: MainComponent
      },
      {
        path: 'CGPA_Report',
        component: StudentCgpaComponent
      },
      {
        path: 'SGPA_Report',
        component: StudentSGPAReportComponent
      },
      {
        path: 'visualcontent',
        component: VisualEditorComponent
      },
      {
        path: 'foldermanagemnet',
        component: FolderComponent
      },
      {
        path: 'semester',
        component: SemesterComponent
      },
      {
        path: 'studentAdmission',
        component: StudentAdmissionComponent
      },
      {
        path: 'addstudentAdmission',
        component: AddStudentAdmissionComponent
      },
      {
        path: 'addNewTeacher',
        component: AddNewTeacherComponent
      },
      {
        path: 'addNewTeacher/:id',
        component: AddNewTeacherComponent
      },
      {
        path: 'NewTeacher',
        component: NewTeacherComponent
      },
      {

        path: 'feesType',
        component: FeesTypeComponent
      },
      {
        path: 'feesDescription',
        component: FeeDescriptionComponent
      },
      {
        path: 'editFees',
        component: EditFeesComponent
      },
      {
        path: 'editFees/:id',
        component: EditFeesComponent
      },
      {
        path: 'curriculum',
        component: CurriculumComponent
      },
      {
        path: 'AcademicYear',
        component: AcademicYearComponent
      },
      {
        path: 'feeReceivable',
        component: FeeReceivableComponent
      },
      {
        path: 'studentFeeReceivable',
        component: StudentFeereceivableComponent
      },
      {
        path: 'studentRegistrationApproval',
        component: StudentRegistrationApprovalComponent
      },

      {
        path: 'contentRepository',
        component: ContentRepoComponent
      },
      {
        path: 'contentRepository/:id',
        component: ContentRepoAddComponent
      },
      {
        path: 'contentSubject',
        component: ContentSubjectComponent
      },
      {
        path: "contentChapter",
        component: ContentChapterComponent
      },

      {
        path: "contentClass",
        component: ContentClassComponent
      },
      {
        path: 'contentResources',
        component: ContentResourcesComponent
      },
      {
        path: 'bookAllocation',
        component: BookAllocationReportComponent
      },
      {
        path: 'viewReport',
        component: BookAllocationViewReportComponent
      },
      {
        path: 'assignedBooks',
        component: FetchAssignedBooksComponent
      },
      {
        path: 'studentReport',
        component: StudentsReportComponent
      },
      {
        path: "returnDate",
        component: ReturnDateComponent
      },
      {
        path: 'updateReturnDate',
        component: UpdateReturnDateComponent
      },
      {
        path: 'libraryManagementSystem',
        component: LibraryManagementSystemComponent
      },
      {
        path: 'libraryBooksAllocated',
        component: LibraryBooksAllocationComponent
      },
      {
        path: 'listofbooks',
        component: ListOfBooksComponent

      },
      {
        path: 'department',
        component: DepartmentComponent
      },
      {
        path: 'holidayNotification',
        component: HolidayNotificationComponent
      },
      {
        path: 'viewNews/:id',
        component: ViewNewsComponent
      },
      {
        path: 'viewNews',
        component: ViewNewsComponent
      },
      {
        path: 'viewDiscussion/:id',
        component: ViewDiscussionComponent
      },
      {
        path: 'viewDiscussion',
        component: ViewDiscussionComponent
      },
      {
        path: 'course',
        component: CourseComponent
      },
      {
        path: 'viewCourse',
        component: ViewCourseComponent
      },
      { path: 'change-password', component: ChangePasswordComponent },
      { path: 'view-all-details', component: ViewAllDetailsComponent },
      { path: 'all-courses', component: CoursesComponent },
      { path: 'my-courses', component: MyCoursesComponent },
      { path: 'my-tutors', component: MyTutorsComponent },
      { path: 'my-payments', component: MyPaymentsComponent },
      { path: 'my-reviews', component: MyReviewComponent },
      { path: 'enrolled-student', component: EnrolledStudentsComponent },
      { path: 'tutor-payments', component: TutorPaymentsComponent },
      { path: 'my-language', component: MyLanguageComponent },
      { path: 'levelof-learner', component: LevelofLearnerComponent },
      { path: 'trainer-dashboard', component: TrainerDashboardComponent },
      { path: 'admin-dashboard', component: AdminDashboardComponent },
      { path: 'configure-images', component: ConfigureImagesComponent },
      { path: 'approval-domain', component: ApprovalDomainComponent },
      { path: 'approve', component: ApproveComponent },
      { path: 'recording', component: RecordingScheduleComponent },
      { path: 'course-report', component: CourseReportComponent },
      { path: 'trainer-report', component: TrainerReportComponent },
      { path: 'trainee-report', component: TraineeReportComponent },
      { path: 'fee-report', component: FeeReportComponent },
      { path: 'notification', component: NotificationComponent }, 
      { path: 'learning-resources', component: LearningResourcesComponent }

    ],

  },
  // {
  //   path: '',
  //   redirectTo: 'login',
  //   pathMatch: 'full'
  // },
  // {
  //   path: 'login',
  //   component: LoginComponent
  // },
  // {
  //   path: 'Home/dashboard',
  //   redirectTo: 'home',
  // },
  // {
  //   path: '**',
  //   redirectTo: 'home'
  // }
  {
    path: '',
    redirectTo: 'tutore-home',
    pathMatch: 'full'
  },
  {
    path: 'tutore-home', component: TutorHomeComponent, resolve: { courseData: CoursesResolver }
  },
  {
    path: 'student-home', component: StudentHomeComponent, resolve: { courseData: CoursesResolver }
  },
  {
    path: 'auto-demo', component: AutocompleteDemoComponent
  },
  { path: 'view-qrcode', component: ViewQrcodeComponent },
  {
    path: 'all-category-courses',
    component: AllCategoryCoursesComponent
  },
  { path: 'view-course-details', component: ViewCourseDetailsComponent },

  { path: 'all-category-courses', component: AllCategoryCoursesComponent },
  { path: 'login', component: LoginComponent },
  { path: 'signup', component: TutorSignupComponent },
  { path: 'student-signup', component: StudentSignupComponent },
  { path: 'invoice', component: InvoiceComponent },
  { path: 'wellcome', component: WellcomeRegistraionComponent },
  { path: 'company-registration', component: CompanyRegistrationComponent },
  
  
  {
    path: 'eRP', component: ERPComponent,
    children: [
      { path: 'about-us', component: AboutUsComponent },
      { path: 'contact-us', component: ContactUs1Component },
      { path: 'refund-policy', component: RefundPolicyComponent },
      { path: 'terms-conditions', component: TermsConditionsComponent },
      { path: 'privacy-policy', component: PrivacyPolicyComponent },
      { path: 'view-course-details', component: ViewCourseDetailsComponent },
      { path: 'view-profile', component: ViewProfileComponent },
      // { path: 'view-profile:/id', component: ViewProfileComponent },
    ]
  },
  { path: '**', redirectTo: 'tutore-home' },
];

@NgModule({
  // imports: [RouterModule.forRoot(routes, { useHash: true })],
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

// resolve:{menu:Resolver},
