<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">

                    <div class="container-fluid" *ngIf="roleId==3">

                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0">My Learning Resources</h5>
                                    </div>
                                    <div class="card-body">
                                        <div *ngIf="course.classes&&course.classes.length">

                                            <table id="patreggraph" datatable [dtOptions]="dtOptions"
                                                [dtTrigger]="dtTrigger"
                                                class="table table-striped table-bordered mt-2 pt-2 table-sm small display"
                                                style="width:100%">
                                                <thead class="top" style="background-color: #624fd7; color:#ffffff;">
                                                    <tr>
                                                        <th> Name</th>
                                                        <!-- <th>Category</th> -->
                                                        <th>Resources</th>
                                                        <th>Exam</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of course.classes">
                                                        <td>{{item.Name}}</td>
                                                        <!-- <td>Medical</td> -->
                                                        <td><a class="a_click underLine"
                                                                (click)="navigate(item,'HOME/learningmaterial')">view</a>
                                                        </td>
                                                        <td><a class="a_click underLine"
                                                                (click)="navigate(item,'HOME/postassessment')">Take
                                                                Exam</a></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="text-dark" *ngIf="!course.classes|| !course.classes.length">
                                            No records to Display
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>