<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0">Locations</h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="text-right">
                                            <button type="button" class="btn btn-primary mb-1" data-bs-toggle="modal"
                                                data-bs-target="#myModal" (click)="add()">Add</button>
                                        </div>
                                        <div *ngIf="table.length">
                                            <table id="patreggraph" datatable
                                            class=" table-striped table-bordered mt-2 pt-2 table-sm small"
                                            style="width:100%">
                                            <thead class="top" style="background-color: #624FD7; color:#ffffff;">
                                                    <tr>
                                                        <th>
                                                            Location Name</th>
                                                        <th> Email Address </th>

                                                        <th>Status</th>
                                                        <th>Edit</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of table">
                                                        <td>{{item.LOCATION_NAME}}</td>
                                                        <td>{{item.LOCATION_EMAILID}}</td>
                                                        <td>{{item.STATUS_DESCRIPTION}}</td>
                                                       
                                                        <td style="display: flex;"> 
                                                            <span data-bs-toggle="modal" data-bs-target="#myModal"
                                                            src=""
                                                            class="edit_icon" (click)="edit(item)"> 
                                                            <i class="fas fa-pen pt-1 mt-1"></i></span ></td>
                                                      
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="text-center" *ngIf="!table.length">
                                            No Records to display
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="myModal" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header py-2">
                <h6 *ngIf="!isEdit" class="modal-title w-100"> Add Locations</h6>
                <h6 *ngIf="isEdit" class="modal-title w-100"> Edit Locations</h6>
                <button type="button" (click)="close()" class="close" data-bs-dismiss="modal"><i class="fas fa-times"></i></button>
            </div>

            <!-- Modal body -->
            <div class="modal-body">
                <form class="well form-horizontal" [formGroup]="myForm" (ngSubmit)="onSubmit(myForm)">
                    <fieldset>
                        <div class="form-group row">
                            <label class="col-md-4 control-label">
                                Location Name <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <input formControlName='LOCATION_NAME'
                                        class="form-control form-control-sm shadow-sm rounded-lg">

                                </div>
                            </div>
                        </div>
                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label">
                                Street Name <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <input formControlName='LOCATION_ADDRESS1'
                                        class="form-control form-control-sm shadow-sm rounded-lg">

                                </div>
                            </div>
                        </div>

                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label">Country<span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <select [(ngModel)]='countryId' (change)="countryChange()"
                                        class="form-control form-control-sm shadow-sm rounded-lg"
                                        formControlName="LOCATION_COUNTRY_ID">
                                        <option value="" [selected]="true">select</option>
                                        <option *ngFor="let item of countrys" [value]='item.DICTIONARYID'>
                                            {{item.DICTIONARYNAME}}
                                        </option>

                                    </select>
                                </div>
                            </div>
                        </div>

                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label">State<span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <select [(ngModel)]='stateId' (change)="stateChange()"
                                        class="form-control form-control-sm shadow-sm rounded-lg"
                                        formControlName="LOCATION_PROVINCE_ID">
                                        <option value="" [selected]="true">select</option>
                                        <option *ngFor="let item of states" [value]='item.DICTIONARYID'>
                                            {{item.DICTIONARYNAME}}
                                        </option>

                                    </select>
                                </div>
                            </div>
                        </div>


                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label">City<span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <select class="form-control form-control-sm shadow-sm rounded-lg"
                                        formControlName="LOCATION_CITY">
                                        <option value="" [selected]="true">select</option>
                                        <option *ngFor="let item of cities" [value]='item.DICTIONARYID'>
                                            {{item.DICTIONARYNAME}}
                                        </option>

                                    </select>
                                </div>
                            </div>
                        </div>


                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label line_2 rem_1">
                                Zip Code<span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <input type="number" min=0 formControlName='LOCATION_ZIP' placeholder=""
                                        class="form-control">
                                </div>
                            </div>
                        </div>
                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label line_2 rem_1">
                                Contact Person <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <input type="text" formControlName='LOCATION_CONTACT_PERSON' placeholder=""
                                        class="form-control">
                                </div>
                            </div>
                        </div>
                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label line_2 rem_1">
                                Cell Phone No<span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <input type="text" formControlName='LOCATION_PHONE1'
                                        onkeypress='return (event.charCode >= 48 && event.charCode <= 57)'
                                        placeholder="" class="form-control">
                                </div>
                                <div *ngIf="myForm.get('LOCATION_PHONE1').touched">
                                    <span class="text-danger"
                                        *ngIf="myForm.get('LOCATION_PHONE1').hasError('required')">
                                        This field is required.
                                    </span>
                                    <span class="text-danger"
                                        *ngIf="myForm.get('LOCATION_PHONE1').hasError('minlength')||myForm.get('LOCATION_PHONE1').hasError('maxlength')">
                                        This field is required 10 characters
                                    </span>

                                </div>
                            </div>

                        </div>

                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label line_2 rem_1">
                                Cell Phone No2<span class="text-danger"></span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <input type="text"
                                        onkeypress='return (event.charCode >= 48 && event.charCode <= 57)'
                                        formControlName='LOCATION_PHONE2' placeholder="" class="form-control">
                                </div>
                            </div>
                        </div>
                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label line_2 rem_1">
                                Email Id<span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <input type="email" formControlName='LOCATION_EMAILID' placeholder=""
                                        class="form-control">
                                </div>
                            </div>
                        </div> 
                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label">Status</label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <select class="form-control form-control-sm shadow-sm rounded-lg"
                                        formControlName="LOCATION_STATUS">
                                        <option [ngValue]='1'>Active</option>
                                        <option [ngValue]='0'>InActive </option>
                                    </select>
                                </div>
                            </div>
                        </div>


                    </fieldset>
                </form>

            </div>
            <div class="modal-footer">
                <button type="button" *ngIf="!isEdit" class="btn btn-sm  btn-save" (click)="onSubmit(myForm)"
                    [disabled]="myForm.invalid">Save</button>
                <button type="button" *ngIf="isEdit" class="btn btn-sm  btn-save" (click)="onSubmit(myForm)"
                    [disabled]="myForm.invalid">Update</button>
                <button type="button" class="btn btn-danger btn-sm" id="md_close" (click)="close()"
                    data-bs-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>