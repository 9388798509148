<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0 ">Category</h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="text-right mb-1" *ngIf="isSuperAdmin">
                                            <button type="button" class="btn btn-primary" data-bs-toggle="modal"
                                                (click)="add()" data-bs-target="#myModal">Add</button>
                                        </div>
                                        <div class="form-group ">
                                            <div class="row">
                                                <div *ngIf='isSuperAdmin' class="col-md-3">
                                                    <label for="sel1">Tenant Name : </label>
                                                    <select [(ngModel)]='tId ' (change)="changeTname()"
                                                        class="form-control form-control-sm shadow-sm rounded-lg"
                                                        id="sel1">
                                                        <option value="" [selected]="true">select</option>
                                                        <option *ngFor="let item of tenanates" [value]='item.TNT_CODE'>
                                                            {{item.TNT_NAME}}</option>
                                                    </select>

                                                </div>
                                            </div>
                                        </div>
                                        <div class="pt-4">
                                            <table datatable id="DataTables_Table_0" [dtOptions]="dtOptions"
                                                [dtTrigger]="dtTrigger"
                                                class=" table-striped table-bordered mt-2 pt-2 table-sm small"
                                                style="width:100%">
                                                <thead class="top" style="background-color: #624FD7; color:#ffffff;">
                                                    <tr>
                                                        <th style="width: 5%;"> S.No </th>
                                                        <th> Category </th>
                                                        <th *ngIf="isSuperAdmin"> Action </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of table let i=index">
                                                        <td>{{i+1}}</td>
                                                        <td>{{item.COURSE_CATEGORY_NAME}}</td>


                                                        <td style="display: flex;" *ngIf="isSuperAdmin">
                                                            <span data-bs-toggle="modal" data-bs-target="#myModal"
                                                                src="" class="edit_icon" (click)="edit(item)">
                                                                <i class="fas fa-pen pt-1 mt-1"></i></span>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="text-center" *ngIf="false">
                                            No Records to display
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>



<div class="modal" id="myModal" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog ">
        <div class="modal-content">

            <!-- Modal Header  -->
            <div class="modal-header py-2">
                <h4 *ngIf="!isEdit" class="modal-title w-100"> Add Course Category</h4>
                <h4 *ngIf="isEdit" class="modal-title w-100"> Edit Course Category</h4>
                <button type="button" (click)="close()" class="close" data-bs-dismiss="modal"><i
                        class="fas fa-times"></i></button>
            </div>

            <!-- Modal body  -->
            <div class="modal-body">
                <form class="well form-horizontal" [formGroup]="myForm">
                    <fieldset>
                        <div class="form-group row">
                            <label class="col-md-4 control-label line_2 rem_1">
                                Name <span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <input placeholder="" class="form-control" formControlName="MNAME" 
                                    >
                                </div>
                            </div>
                            <div *ngIf="myForm.get('MNAME').touched">
                                <span class="text-danger" *ngIf="myForm.get('MNAME').hasError('required')">
                                    Category Name is required.
                                </span>

                            </div>
                           
                        </div>
                        <!-- <div class="form-group row pt-4">
                            <label class="col-md-4 control-label line_2 rem_1">
                                Description </label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <textarea placeholder="" class="form-control"
                                        formControlName="MDESCRIPTION"></textarea>
                                </div>
                            </div>
                        </div> -->

                   

                        <div div class="form-group row pt-4">
                            <div class="card h-100">
                                <div class="card-header">
                                    <h6 class="mb-0">Category Image <span class="text-danger">*</span></h6>
                                </div>
                                <div class="card-body text-center">
                                    <img [src]="imageURL" class="img-fluid">
                                </div>
                                <div class="card-footer bg-white">
                                   
                                   
                                    <input type="file" id="materialFile1" style="min-height: 30px;" placeholder=""
                                        (change)="changeFile($event)"  formControlName="IMAGE_URL"
                                        accept="'png', 'jpg', 'PNG', 'jpeg', 'gif', 'JPEG', 'image'"
                                        class="form-control form-control-sm shadow-sm rounded-lg d-none"
                                      >
                                    <button type="button"
                                        (click)="onCategoryImage()"
                                        class="btn btn-primary d-grid gap-2 mx-auto col-7">Upload Image</button>
                                </div>
                                
                                
                            </div>
                        </div>



                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label">Status</label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <select class="form-control form-control-sm shadow-sm rounded-lg" id="sel1"
                                        formControlName="MSTATUS">
                                        <option value=true>Active</option>
                                        <option value=false>InActive </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" *ngIf="!isEdit" (click)="onSubmit(myForm)"
                    class="btn btn-sm  btn-save">Save</button>
                <button type="button" *ngIf="isEdit" (click)="onSubmit(myForm)"
                    class="btn btn-sm  btn-save">Update</button>
                <button type="button" class="btn btn-danger btn-sm" id='md_close' (click)="close()"
                    data-bs-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>