<input type="button" id="btnOpenModel" class="d-none" data-bs-toggle="modal" data-bs-target="#myModal">
<div class="modal" id="myModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog ">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header py-2 " style="border-radius: 0pxs;">
                <h4 class="modal-title w-100"> Change Password</h4>
                <button type="button" class="close" (click)="close()" data-bs-dismiss="modal"><i class="fas fa-times"></i></button>
            </div>

            <!-- Modal body -->
            <div class="modal-body">
                <form class="well form-horizontal" [formGroup]="changePasswordForm">
                    <fieldset>
                        <div class="form-group row mb-3 validate-input">
                            <label class="col-md-4 control-label line_2 rem_1">
                                Old Password<span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <input  id="txtOldpassword" type="password"  class="form-control" placeholder="Please enter your old password" formControlName="OLD_PWD"> 
                                </div>
                                <div *ngIf="changePasswordForm.get('OLD_PWD').touched && validate">
                                    <span class="text-danger form-text"
                                        *ngIf="changePasswordForm.get('OLD_PWD').hasError('required')">
                                        This field is required.
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row mb-3 validate-input">
                            <label class="col-md-4 control-label line_2 rem_1">
                                New Password<span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <input placeholder="Please enter your new password" type="password" class="form-control" formControlName="NEW_PWD"> 
                                </div>
                                <div *ngIf="changePasswordForm.get('NEW_PWD').touched && validate">
                                    <span class="text-danger form-text"
                                        *ngIf="changePasswordForm.get('NEW_PWD').hasError('required')">
                                        This field is required.
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row mb-3 validate-input">
                            <label class="col-md-4 control-label line_2 rem_1">
                                Confirm Password<span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <input placeholder="Please enter your confirm password" type="password" class="form-control" formControlName="CONFIRM_PWD" > 
                                </div>
                                <div *ngIf="changePasswordForm.get('CONFIRM_PWD').touched && validate">
                                    <span class="text-danger form-text"
                                        *ngIf="changePasswordForm.get('CONFIRM_PWD').hasError('required')">
                                        This field is required.
                                    </span>
                                </div>
                            </div>
                        </div>

                    </fieldset>
                </form>
            </div>
            <div class="modal-footer">
                <button type="submit" (click)="onSubmit(changePasswordForm)" class="btn btn-sm   btn-save ps-4 pe-4">Save</button>
                <button type="button" id="btnCloseModel" (click)="close()" class="btn btn-danger btn-sm ps-4 pe-4" data-bs-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>