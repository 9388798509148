<!-- Button trigger modal -->
<input type="button" id="btnOpenModel" class="d-none" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
<!-- <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
    Launch static backdrop modal
</button> -->

<!-- Modal -->
<div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-md">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="staticBackdropLabel">View Id Card</h5>
                <button type="button" class="btn-close" (click)="closeModel()" data-bs-dismiss="modal"
                    aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <!-- PDF File -->
                <img  [src]="urlSafe" width="100%" height="100%"
                    style="border: none;">
            <!-- </iframe> -->
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" (click)="closeModel()"
                    data-bs-dismiss="modal">Close</button>
                <!-- <button type="button" class="btn btn-primary">Understood</button> -->
            </div>
        </div>
    </div>
</div>