<section>
    <div class="section page-banner-section" style="background-image: url('../../../../assets/img/inner-banner.png');">
        <div class="shape-2"></div>
        <div class="container">
            <div class="page-banner-wrap">
                <div class="row">
                    <div class="col-lg-12">
                        <!-- Page Banner Content Start -->
                        <div class="page-banner text-center">
                            <h2 class="title">About Us  </h2>
                            <ul class="breadcrumb justify-content-center">
                                <li class="breadcrumb-item"><a href="#">Home</a></li>
                                <li class="breadcrumb-item active" aria-current="page">About Us </li>
                            </ul>
                        </div>
                        <!-- Page Banner Content End -->
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    </section>
    
    <section class="pt-4 pb-4">
        <div class="container">
            <div class="row">
                <div class="col-xl-12">
                            <h5 class="title">About Us</h5>
                           <p class="text-dark"> You've arrived at the ideal location whether you want to learn something new or impart your knowledge. We connect people through knowledge as a provider of online education.</p>
                       <p class="text-dark">Since the beginning of Shiksion in 2020, we have focused primarily on developing information systems for organisations that offer training, counselling, support, and employment services.
                        All of us at Shiksion are both students and teachers. Every day, we put our values into practise in order to foster a culture that is inclusive of all people and dedicated to fostering the success of its employees.
                        </p>
                        <p class="text-dark">Our headquarters are in Hyderabad, but we've always maintained a truly global perspective. Since 2006, when our business was founded in Hyderabad. Additionally, we collaborate directly with organisations to train their staff and volunteers by offering them free courses.</p>
                       <p class="text-dark">
                        Products that have been tested to work from the ground up and are mature and stable are a reflection of our experience. All of our product development has been guided by user group forums and feedback from customers, resulting in systems that are not only practical and easy to use but also have all the features businesses require to manage their contracts.
                       </p>
                </div>
            
            </div>
        </div>
    </section>
    <!-- <section class="pt-4 pb-4">
        <div class="container-fluid">
            <div class="row">
                <div class="col-xl-12">

                    <div class="">
    
                    <div class="">
                        <div class="">
                            <h5 class="title">About Us</h5>
                           <p class="text-dark"> You've arrived at the ideal location whether you want to learn something new or impart your knowledge. We connect people through knowledge as a provider of online education.</p>
                       <p class="text-dark">Since the beginning of Shiksion in 2020, we have focused primarily on developing information systems for organisations that offer training, counselling, support, and employment services.
                        All of us at Shiksion are both students and teachers. Every day, we put our values into practise in order to foster a culture that is inclusive of all people and dedicated to fostering the success of its employees.
                        </p>
                        <p class="text-dark">Our headquarters are in Hyderabad, but we've always maintained a truly global perspective. Since 2006, when our business was founded in Hyderabad. Additionally, we collaborate directly with organisations to train their staff and volunteers by offering them free courses.</p>
                       <p class="text-dark">
                        Products that have been tested to work from the ground up and are mature and stable are a reflection of our experience. All of our product development has been guided by user group forums and feedback from customers, resulting in systems that are not only practical and easy to use but also have all the features businesses require to manage their contracts.
                       </p>

                        </div>
                    </div>
    
    </div>
                </div>
            
            </div>
        </div>
    </section> -->