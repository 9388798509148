<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0 ">Trainer Report</h5>
                                    </div>
                                    <div class="card-body">
                                                                              
                                        <div class="row">
                                            <div class="col-md-3">
                                                <label for="sel1">Course Name : </label>
                                                <select [(ngModel)]='course_id '
                                                    class="form-select form-select-sm shadow-sm rounded-lg"
                                                    id="sel1" (change)="trainerReportChange()">
                                                    <option value="0" [selected]="true">Select All</option>
                                                    <option *ngFor="let course of courcesList"
                                                        [value]='course.COURSE_ID'>
                                                        {{course.COURSE_NAME}}
                                                    </option>

                                                </select>
                                            </div>

                                        </div>&nbsp;

                                        <div  class="table-responsive">
                                            <table id="trainerReport" datatable [dtOptions]="dtOptions"
                                            [dtTrigger]="dtTrigger"
                                                class=" table-striped table-bordered mt-2 pt-2 table-sm small display"
                                                style="width:100%" >
                                                <thead class="top" style="background-color: #624FD7; color:#ffffff;">
                                                    <tr style="white-space: nowrap;">
                                                        <th> S.No </th>
                                                        <th> Trainer  Name</th>
                                                        <th> Course </th>
                                                        <th> Student Level </th>
                                                        <th> Start Date </th>
                                                        <th> End Date </th>
                                                        <th> Trainee Fee </th>
                                                        <!-- <th> status </th> -->
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of trainerReportList; let i=index">
                                                        <td>{{i+1}}</td>
                                                        <td>{{item.name}}</td>
                                                        <td>{{item.course_name}}</td>
                                                        <!-- <td>{{item.courseshd_student_level}}</td> -->
                                                        <td>{{item.studentLevelName}}</td>
                                                        <td>{{item.startdate | date:'dd-MM-yyyy'}}</td>
                                                        <td>{{item.enddate | date:'dd-MM-yyyy'}}</td>
                                                        <td>{{item.amount |currency:'INR'}}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <!-- <div class="text-center" *ngIf="!trainerReportList.length">
                                            No Records to display
                                        </div> -->
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>