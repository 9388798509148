<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">

                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0">VC Details</h5>
                                    </div>

                                    <div class="card-body">
                                        <div class="form-group">
                                            <div class="row">
                                                <div class="col-md-3">
                                                    <label for="sel1">Course Name : </label>
                                                    <select [(ngModel)]='courceId '
                                                        class="form-select form-select-sm shadow-sm rounded-lg"
                                                        id="sel1" (change)="courceChange()">
                                                        <option value="" [selected]="true">select</option>
                                                        <option *ngFor="let course of courses"
                                                            [value]='course.COURSE_ID'>
                                                            {{course.COURSE_NAME}}
                                                        </option>

                                                    </select>
                                                </div>
                                                <div class="col-md-3">
                                                    <label for="sel1">Course Schedule :</label>
                                                    <select [(ngModel)]='schedulId '
                                                        class="form-select form-select-sm shadow-sm rounded-lg"
                                                        id="sel1" (change)="schedulChange()">
                                                        <option value="" [selected]="true">select</option>
                                                        <option *ngFor="let schedul of shedules"
                                                            [value]='schedul.COURSESHD_ID'>
                                                            {{schedul.COURSESHD_NAME}}</option>

                                                    </select>


                                                </div>
                                            </div>


                                        </div>


                                        <div *ngIf="table.length">
                                            <table id="patreggraph" datatable
                                                class="table table-striped table-bordered mt-2 pt-2 table-sm small"
                                                style="width:100%">
                                                <thead class="top" style="background-color: #624FD7; color:#ffffff;">
                                                    <tr>
                                                        <th>Session Name</th>
                                                        <th> Date</th>
                                                        <th>Start Time</th>
                                                        <th> End Time</th>
                                                        <th> Join</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of table">
                                                        <td>{{item.SessionName}}</td>
                                                        <td>{{item.Date}}</td>
                                                        <td>{{item.StartTime}}</td>
                                                        <td>{{item.EndTime}}</td>
                                                        <td><a *ngIf='item.URL' class="join" (click)='joinSession(item)'
                                                                target="_blank">Join</a>
                                                            <span *ngIf='!item.URL'>Host Not Joined</span>
                                                        </td>

                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>