<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0 ">Approval Domain</h5>
                                    </div>

                                    <div class="text-center pt-4" >
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio"  name="isCompany" (change)="getApproval($event.target.value)"
                                             id="company" [value]="true" checked>
                                            <label class="form-check-label" for="subDomain" >Company</label>
                                        </div>
        
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio"  name="isCompany" (change)="getApproval($event.target.value)"
                                            id="company" [value]="false">
                                            <label class="form-check-label">Individual</label>
                                        </div>
                                    </div>


                                    <div class="card-body">
                                        <div>
                                            <div class="table-responsive">
                                                <table id="approvaldomain" datatable [dtOptions]="dtOptions"
                                                    [dtTrigger]="dtTrigger"
                                                    class="table-striped table-bordered mt-2 pt-2 table-sm small display"
                                                    style="width:100%">
                                                    <thead class="top"
                                                        style="background-color: #624FD7; color:#ffffff;">
                                                        <tr style="white-space: nowrap;">
                                                            <th> User Name </th>
                                                            <th> Email Id </th>
                                                            <th> Contact No. </th>
                                                            <th> Company Name </th>
                                                            <th> Approval Status </th>
                                                            <th> SubDomain </th>
                                                            <th> Approved Date </th>
                                                            <th> Rejected date </th>
                                                            <th> Action </th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let item of approvalList">
                                                            <td> {{item.firstname}} </td>
                                                            <td> {{item.username}} </td>
                                                            <td> {{item.mobileno}} </td>
                                                            <td> {{item.company_name}} </td>
                                                            <td> {{item.approval_status == 1 ? "pending" : item.approval_status == 2 ? "Approved":"Reject"}} </td>
                                                            <td> {{item.subdomainname}} </td>
                                                            <td> {{item.approved_date | date:'dd-MM-yyyy'}} </td>
                                                            <td> {{item.rejected_date | date:'dd-MM-yyyy'}} </td>
                                                            <td > 
                                                                <a *ngIf="[1,3].includes(item.approval_status)" href="javascript: void(0)" (click)="changeStatus(item)">Approve</a> 
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-approve *ngIf="isApprovalSubmitted" (closeModelEvent)="closeModel($event)" [editData]="editData"></app-approve>