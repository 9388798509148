import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';
import { BaseComponent } from '../base.component';

@Component({
  selector: 'app-approval-domain',
  templateUrl: './approval-domain.component.html',
  styleUrls: ['./approval-domain.component.css']
})
export class ApprovalDomainComponent extends BaseComponent implements OnInit {
  editData: any;
  approvalList: Array<any> = [];
  isApprovalSubmitted: boolean = false;
  isCompany: Boolean = false;
  // approvalStatus = [
  //   { id: 1, name: "Pending" },
  //   { id: 2, name: "Approved" },
  //   { id: 3, name: "Reject" }
  // ];
  constructor(CommonService: CommonService, toastr: ToastrService) {
    super(CommonService, toastr);
  }

  ngOnInit(): void {
    this.getApproval(true);
  }

  getApproval(val: any) {
    this.isCompany = false;
    this.loadDataTable('approvaldomain');
    this.CommonService.getCall(`Account/GetApprovalList/${val}`).subscribe((res: any) => {
      this.approvalList = res.data;
      setTimeout(() => { this.dtTrigger.next(); }, 100);
    })
  }

  changeStatus(data: any) {
    setTimeout(() => { this.editData = data; this.isApprovalSubmitted = true; }, 10);
  }

  closeModel(data: any) {
    this.isApprovalSubmitted = false;
    this.getApproval(true);
  }




}
