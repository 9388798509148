<div class="custom_container">
    <form class="well form-horizontal" [formGroup]="myform" >
        <fieldset>
            
            <div class="form-group row pt-4">
                <label class="col-md-4 control-label">
                  Account Holder Full Name <span class="text-danger">*</span></label>
                <div class="col-md-8 inputGroupContainer">
                    <div class="input-group"> <strong>:</strong> &nbsp;
                        <input class="form-control" formControlName='ACCOUNT_HOLDER_NAME' type="text" id='nameInput' onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122) || (event.charCode == 32))' >
                    </div>
                    <div *ngIf="myform.get('ACCOUNT_HOLDER_NAME').touched">
                        <span class="text-danger" *ngIf="myform.get('ACCOUNT_HOLDER_NAME').hasError('required')">
                            Account Holder  Name  is required.
                        </span>

                    </div>
                    
                    
                </div>
            </div>
            <div class="form-group row pt-4">
                <label class="col-md-4 control-label">
                   Account Number <span class="text-danger">*</span></label>
                <div class="col-md-8 inputGroupContainer">
                    <div class="input-group"> <strong>:</strong> &nbsp;
                        <input class="form-control" [(ngModel)]="accountnumber" id="accountnumber" formControlName='ACCOUNT_NO' type="tel"  
                        (change)="validateAccountNumber($event)"
                        
                      
                     >
                    </div>
                    <div *ngIf="myform.get('ACCOUNT_NO').touched"> 
                        <span class="text-danger" *ngIf="myform.get('ACCOUNT_NO').hasError('required')">
                            Account Number is required.
                        </span>

                    </div>
                   
                  
                </div>
            </div>
            <div class="form-group row pt-4">
                <label class="col-md-4 control-label">
                  Bank Name <span class="text-danger">*</span></label>
                <div class="col-md-8 inputGroupContainer">
                    <div class="input-group"> <strong>:</strong> &nbsp;
                        <input class="form-control" formControlName='BANK_NAME' type="text" id='nameInput' onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122) || (event.charCode == 32))'>
                    </div>
                    <div *ngIf="myform.get('BANK_NAME').touched">
                        <span class="text-danger" *ngIf="myform.get('BANK_NAME').hasError('required')">
                            Bank Name is required.
                        </span>

                    </div>
                    
                  
                </div>
            </div>
            <div class="form-group row pt-4">
                <label class="col-md-4 control-label">
                   Bank Branch <span class="text-danger">*</span></label>
                <div class="col-md-8 inputGroupContainer">
                    <div class="input-group"> <strong>:</strong> &nbsp;
                        <input class="form-control" formControlName='BRANCH_NAME' type="text" id='nameInput' onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122) || (event.charCode == 32))' >
                    </div>
                    <div *ngIf="myform.get('BRANCH_NAME').touched">
                        <span class="text-danger" *ngIf="myform.get('BRANCH_NAME').hasError('required')">
                            Bank Branch is required.
                        </span>

                    </div>
                   
                  
                </div>
            </div>
            <div class="form-group row pt-4">
                <label class="col-md-4 control-label">
                    IFSC Code<span class="text-danger">*</span></label>
                <div class="col-md-8 inputGroupContainer">
                    <div class="input-group"> <strong>:</strong> &nbsp;
                        <input class="form-control" formControlName='IFSC_CODE' type="text"  >
                    </div>
                    <div *ngIf="myform.get('IFSC_CODE').touched">
                        <span class="text-danger" *ngIf="myform.get('IFSC_CODE').hasError('required')">
                            IFSC Code is required.
                        </span>

                    </div>
                   
                  
                </div>
            </div>
           
           
        </fieldset>
    </form>

  

</div>