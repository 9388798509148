<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">

                <div class="row">
                    <div class="col-xl-12">
                        <div class="card rounded shadow-lg">
                            <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                <h5 class="mb-0"> Blogs</h5>
                                <!-- data-toggle="modal" data-target="#myModal" -->
                            </div>
                            <div class="card-body">
                                <div class="mb-2" style="display: flex;justify-content: space-between;">
                                    <button data-bs-toggle="modal" data-bs-target="#myModal"
                                    class="btn btn-sm  btn-save">Compose New Post</button>
                                    <div class="float-right" style="display: flex;">
                                        <input [(ngModel)]='searchText' type="text"
                                            placeholder="search by post title  or label" class="form-control">&nbsp;<i
                                            class="fa fa-search icon_search text-primary pointer" (click)='search()'
                                            aria-hidden="true"></i>
                                    </div>
                                </div>
                                <div>
                                    <div class="card flex-row flex-wrap mb-1" *ngFor='let item of blogs'>
                                        <div class="card-heade border-0">
                                            <img src="//placehold.it/200" alt="">
                                        </div>
                                        <div class="card-block px-2">
                                            <h4 class="card-title mt-1">{{item.BlogTitle}}</h4>
                                            <span class="text-dark" style="font-size: 12px;">{{item.NAME}}
                                                &nbsp; {{item.CreatedOn}}</span>
                                            <p class="card-text">{{item.BlogMessage}}</p>
                                            <a (click)="view(item)" class="btn text-danger">Continue
                                                Reading...</a>
                                        </div>
                                        <div class="w-100"></div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div class="modal" id="myModal" data-bs-backdrop="static" data-bs-keyboard="false">
                <div class="modal-dialog ">
                    <div class="modal-content">

                        <!-- Modal Header -->
                        <div class="modal-header py-2">
                            <h4 class="modal-title w-100"> Compose Post</h4>
                            <button type="button" (click)="close()" class="btn-close"
                                data-bs-dismiss="modal"><i class="fas fa-times"></i></button>
                        </div>

                        <!-- Modal body -->
                        <div class="modal-body">
                            <form class="well form-horizontal" [formGroup]="myForm">
                                <fieldset>
                                    <div class="form-group row">
                                        <label class="col-md-4 control-label line_2 rem_1">
                                            Title <span class="text-danger">*</span></label>
                                        <div class="col-md-8 inputGroupContainer">
                                            <div class="input-group">
                                                <input class="form-control form-control-sm shadow-sm rounded-lg"
                                                    formControlName="BlogTitle">

                                            </div>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="form-group row pt-4">
                                        <label class="col-md-4 control-label line_2 rem_1">
                                            lable <span class="text-danger">*</span></label>
                                        <div class="col-md-8 inputGroupContainer">
                                            <div class="input-group">
                                                <input type="text" placeholder="" class="form-control"
                                                    formControlName='Labels'>
                                            </div>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="form-group row  pt-4">
                                        <label class="col-md-4 control-label line_2 rem_1">
                                            Reader Comments <span class="text-danger">*</span></label>
                                        <div class="col-md-8 inputGroupContainer">
                                            <div class="input-group">
                                                <div class="radio">
                                                    <div class="form-check-inline">
                                                        <label class="form-check-label">
                                                            <input value=1 type="radio" formControlName='IsCommentsShow'
                                                                class="form-check-input" name="IsCommentsShow">Allow
                                                        </label>
                                                    </div>
                                                    <div class="form-check-inline">
                                                        <label class="form-check-label">
                                                            <input value=0 type="radio" formControlName='IsCommentsShow'
                                                                class="form-check-input" name="IsCommentsShow">Don't
                                                            Allow
                                                        </label>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="form-group row  pt-4">
                                        <label class="col-md-4 control-label">Message <span
                                                class="text-danger">*</span></label>
                                        <div class="col-md-8 inputGroupContainer">
                                            <div class="input-group">
                                                <textarea formControlName='BlogMessage'
                                                    class="form-control form-control-sm shadow-sm rounded-lg">

                                    </textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="form-group row pt-4">
                                        <label class="col-md-4 control-label line_2 rem_1">
                                            Upload Image </label>
                                        <div class="col-md-8 inputGroupContainer">
                                            <div class="input-group">
                                                <input type="file" (change)="changeFile($event)" placeholder=""
                                                    class="form-control">
                                            </div>
                                            <p>{{fileName}}</p>
                                        </div>
                                    </div>
                                    <br>
                                </fieldset>
                            </form>

                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-sm  btn-save" (click)="onSubmit(myForm,'save')"
                                [disabled]="myForm.invalid">Save</button>
                            <button type="button" class="btn btn-sm  btn-save" (click)="onSubmit(myForm,'publish')"
                                [disabled]="myForm.invalid">Publish</button>
                            <button type="button" class="btn btn-danger btn-sm" id="md_close" (click)="close()"
                                data-bs-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>