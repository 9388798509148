<div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
        <div class="container-fluid">
            <div class="default_class">
                <div class="content-wrapper p-0">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card rounded shadow-lg">
                                    <div class="card-header  headerbg" style="border-bottom:3px solid #d8eff8;">
                                        <h5 class="mb-0 ">Topics</h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="text-right mb-1">
                                            <button type="button" class="btn btn-sm btn-primary" data-bs-toggle="modal"
                                                data-bs-target="#myModal"  *ngIf="isPermission">Add</button>
                                        </div>
                                        <div class="form-group ">
                                            <div class="row">
                                                <div *ngIf='isSuperAdmin' class="col-md-3">
                                                    <label for="sel1">Tenant Name : </label>
                                                    <select [(ngModel)]='tId ' (change)="changeTname()"
                                                        class="form-control form-control-sm shadow-sm rounded-lg"
                                                        id="sel1">
                                                        <option value="" [selected]="true">select</option>
                                                        <option *ngFor="let item of tenanates" [value]='item.TNT_CODE'>
                                                            {{item.TNT_NAME}}</option>
                                                    </select>

                                                </div>
                                            </div>
                                        </div>
                                        <div class="pt-2">
                                            <table datatable id="DataTables_Table_0" [dtOptions]="dtOptions"
                                                [dtTrigger]="dtTrigger"
                                                class=" table-striped table-bordered mt-2 pt-2 table-sm small"
                                                style="width:100%">
                                                <thead class="top" style="background-color: #624FD7; color:#ffffff;">
                                                    <tr>
                                                        <th> Course Name </th>
                                                        <th> Batch Schedule  </th>
                                                        <th> Topics name </th>
                                                        <!-- <th> Course Outcome</th> -->
                                                        <th> Action </th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of table">
                                                        <td>{{item.CourseName}}</td>
                                                        <td>{{item.COURSESHD_NAME}}</td>
                                                        <td>{{item.CHAPTER_NAME}}</td>
                                                        <!-- <td>{{item.CHAPTER_DESCRIPTION}}</td> -->
                                                        <!-- <td></td> -->
                                                        <td style="display: flex;">   <span data-bs-toggle="modal" data-bs-target="#myModal"
                                                            src=""
                                                            class="edit_icon" (click)="edit(item)" *ngIf="isPermission"> 
                                                            <i class="fas fa-pen pt-1 mt-1"></i></span >
                                                            <button class="btn " data-bs-toggle="modal"
                                                                (click)="assign(item)" data-bs-target="#myModal1"> Assign
                                                                PO-CO</button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="text-center" *ngIf="false">
                                            No Records to display
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>



<div class="modal" id="myModal" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog  modal-lg">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header py-2">
                <h6 *ngIf="!isEdit" class="modal-title w-100"> Add Topic</h6>
                <h6 *ngIf="isEdit" class="modal-title w-100"> Edit Topic</h6>
                <button type="button" (click)="close()" class="close" data-bs-dismiss="modal"><i class="fas fa-times"></i></button>
            </div>

            <!-- Modal body -->
            <div class="modal-body">
                <form class="well form-horizontal" [formGroup]="myForm" (ngSubmit)="onSubmit(myForm)">
                    <fieldset>
                        <div class="form-group row">
                            <label class="col-md-4 control-label">Course Name</label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <select [attr.disabled]="this.isEdit"
                                    class="form-select form-select-sm shadow-sm rounded-lg"
                                        (change)="loadChapterShedule($event.target.value)"
                                        formControlName="CHAPTER_COURSE_ID">
                                        <option value="" [selected]="true">select</option>
                                        <option *ngFor="let item of courses" [value]="item.COURSE_ID">
                                            {{item.COURSE_NAME}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label">Batch Schedule </label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <select class="form-select form-select-sm shadow-sm rounded-lg"
                                        formControlName="CHAPTER_CS_ID">
                                        <option *ngIf="!isEdit" value="" [selected]="true">select</option>
                                        <option *ngFor="let item of shedules" [value]="item.COURSESHD_ID">
                                            {{item.COURSESHD_NAME}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label line_2 rem_1">
                                Chapter Code<span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <input [attr.disabled]="isEdit" placeholder="" class="form-control"
                                        formControlName="CHAPTER_CODE">
                                </div>
                            </div>
                        </div> 
                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label line_2 rem_1">
                                Topic name<span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <input [attr.disabled]="this.isEdit" placeholder="" class="form-control"
                                        formControlName="CHAPTER_NAME">
                                </div>
                            </div>
                        </div>
                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label">
                               Description<span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <textarea placeholder="" class="form-control form-control-sm shadow-sm rounded-lg" formControlName="CHAPTER_DESCRIPTION">
                                        </textarea>
                                </div>
                            </div>
                        </div>
                        

                        <div class="form-group row pt-4">
                            <label class="col-md-4 control-label">Status<span class="text-danger">*</span></label>
                            <div class="col-md-8 inputGroupContainer">
                                <div class="input-group">
                                    <select class="form-select form-select-sm shadow-sm rounded-lg"
                                        formControlName="CHAPTER_STATUS">
                                        <option value=true>Active</option>
                                        <option value=false>InActive </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" *ngIf="!isEdit" class="btn btn-sm  btn-save" (click)="onSubmit(myForm)"
                    [disabled]="myForm.invalid">Save</button>
                <button type="button" *ngIf="isEdit" class="btn btn-sm  btn-save" (click)="onSubmit(myForm)"
                    [disabled]="myForm.invalid">Update</button>
                <button type="button" class="btn btn-danger btn-sm" id='md_close' (click)="close()"
                    data-bs-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="myModal1" data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog  modal-lg">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header py-2">
                <h6  class="modal-title w-100">  Assign</h6>
                
                <button type="button" (click)="close()" class="close" data-bs-dismiss="modal"><i class="fas fa-times"></i></button>
            </div>

            <!-- Modal body -->
            <div class="modal-body">
                <div class="header_main">
                    <div class="row">
                        <div class="col-md-2"></div>
                        <div class="col-md-3">
                            Course Name</div>
                        <div class="col-md-7">:{{assignData.COURSE_NAME}}</div>
                    </div>&nbsp;
                    <div class="row">
                        <div class="col-md-2"></div>
                        <div class="col-md-3">
                            Batch Schedule Name</div>
                        <div class="col-md-7">: {{this.assignData.COURSESHD_NAME}} </div>
                    </div>&nbsp;
                    <div class="row">
                        <div class="col-md-2"></div>
                        <div class="col-md-3">
                            Chapter Name</div>
                        <div class="col-md-7">:{{assignData.CHAPTER_NAME}}</div>
                    </div>&nbsp;
                    <div class="row">
                        <div class="col-md-2"></div>
                        <div class="col-md-3">
                            Course Outcome</div>
                        <div class="col-md-7">:{{assignData.CHAPTER_DESCRIPTION}} </div>
                    </div>&nbsp;
                </div>
                <div class="body_main">
                    <table id="patreggraph" class=" table-striped table-bordered mt-2 pt-2 table-sm small"
                        style="width:100%">
                        <thead>
                            <tr>
                                <th>Program Outcome</th>
                                <th>Points</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of points">
                                <td>
                                    {{item.COURSE_OBJECTIVE}}</td>
                                <td><input type="text" [(ngModel)]="item.CO_PO_POINTS"></td>
                            </tr>

                        </tbody>
                    </table>
                </div>


            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-sm  btn-save" [disabled]="!points.length"
                    (click)="createPoCo()">Save</button>

                <button type="button" class="btn btn-danger btn-sm" id='md_close1' (click)="close()"
                    data-bs-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>