<table class="table table-striped table-responsive-md btn-table table-bordered text-dark">
    <thead class="top" style="background-color: #624FD7; color:#ffffff;">
        <tr>
            <th *ngFor="let header of TableHeadings"> {{header}} </th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor='let data of TableData'>
            <td *ngFor='let column of TableColumns'>{{data[column]}}</td>
        </tr>
    </tbody>
</table>