<div class="row pt-5">
    <div class="col-xl-3 col-md-3 col-6 text-center mb-3">

        <span> <i class="fas fa-graduation-cap fa-2x "></i></span>
        <h5 class="fw-bold pt-3">{{profileCounts.STUDENTS}}</h5>
        <h6 class="">Trainees</h6>

    </div>
    <div class="col-xl-3 col-md-3 col-6 text-center mb-3">

        <span> <i class="fas fa-book fa-2x "></i></span>
        <h5 class="fw-bold pt-3">{{profileCounts.COURSES}}</h5>
        <h6 class="">Courses</h6>

    </div>
    <div class="col-xl-3 col-md-3 col-6 text-center mb-3">

        <span> <i class="fas fa-comment-dots fa-2x "></i></span>
        <h5 class="fw-bold pt-3">{{profileCounts.REVIEWS}}</h5>
        <h6 class="">Reviews</h6>

    </div>
    <div class="col-xl-3 col-md-3 col-6 text-center mb-3">

        <span> <i class="far fa-calendar-check fa-2x "></i></span>
        <h5 class="fw-bold pt-3">{{profileCounts.ONLINE}}</h5>
        <h6 class="">Online Classes</h6>

    </div>

</div>