<div class="limiter">
    <div class="container-login100" style="background-image: url('../../../assets/images/dhanush/bg-image.png');">
        <div class="wrap-login100" style="padding: 22px 55px 24px 55px;">
            <div class="text-center"> <img id="homepageimage_path" src="../../../../assets/img/samvaad_tutor_logo.png" [routerLink]="['']"
                    style="cursor:pointer;" class="img-fluid" />
            </div>
            <br>
            <form class="login100-form validate-form" [formGroup]="signUpForm" (ngSubmit)="onSubmit()">
                <span class="login100-form-title p-b-24"> <span style="font-size:22px;">Sign Up</span> </span> <br>
                <!-- <span style="font-size:15px;">Learning Management System</span> -->
                <div class="wrap-input100 validate-input mt-2" [ngClass]="{ 'is-invalid': submitted && f.name.errors }">
                    <input class="input100" formControlName="name" type="text" name="name" maxlength="100"
                        (input)="nameValidate($event)">
                    <span class="focus-input100" data-placeholder="Full Name"></span>
                </div>
                <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                    <div *ngIf="f.name.errors.required">Full name is required</div>
                </div>
                <div class="wrap-input100 validate-input mt-4"
                    [ngClass]="{ 'is-invalid': submitted && f.mobile.errors }">
                    <input class="input100" formControlName="mobile" type="text" name="mobile" maxlength="10"
                        (input)="numberValidate($event)">
                    <span class="focus-input100" data-placeholder="Mobile"></span>
                </div>
                <div *ngIf="submitted && f.mobile.errors" class="invalid-feedback">
                    <div *ngIf="f.mobile.errors.required">Mobile is required</div>
                    <div *ngIf="f.mobile.errors.pattern">Please enter valid mobile</div>
                    <div *ngIf="f.mobile.errors.minlength">Please enter 10 digit number</div>
                </div>
                <div class="wrap-input100 validate-input mt-4" data-validate="Valid email is: a@b.c"
                    [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                    <input class="input100" formControlName="email" type="text" name="email" maxlength="100">
                    <span class="focus-input100" data-placeholder="Email"></span>
                </div>
                <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                    <div *ngIf="f.email.errors.required">Email is required</div>
                    <div *ngIf="f.email.errors.pattern">Please enter valid email</div>
                </div>
                <div class="mt-4">
                    <div>
                        <label class="input-group-append me-3">
                            <strong>Role :</strong>
                        </label>
                        <input id="student" type="radio" name="role" formControlName="role" [value]="true"
                            (change)="onChangerole('student')" /><label for="student" class="ms-1">
                            Student</label>
                        &nbsp;&nbsp;
                        <input id="trainer" type="radio" name="role" formControlName="role" [value]="false"
                            (change)="onChangerole('trainer')" /><label for="trainer" class="ms-1">
                            Trainer/Admin</label>
                    </div>
                </div>
                <div class="mt-3">
                    <label for="exampleInputEmail1" class="form-label" style="color: #908c93;">Registration Type</label>
                    <select class="form-select" aria-label="Default select example" formControlName="regType" #RegistrationType
                        style="padding: 0.375rem 2.25rem 0.375rem 0.75rem;" >
                        <option value="0"> select </option>
                        <option value="1">Individual</option>
                        <option value="2">Company</option>
                    </select>
                </div>

                <div class="mt-3" *ngIf="RegistrationType.value=='2' && isStudent">
                    <label for="exampleInputEmail1" class="form-label" style="color: #908c93;">Select Company
                        Name</label>
                    <select class="form-select" aria-label="Default select example" formControlName="Company_id"
                        style="padding: 0.375rem 2.25rem 0.375rem 0.75rem;">
                        <option value="0"> select </option>
                        <option *ngFor="let item of companyList" [value]='item.COMPANY_ID'>{{item.COMPANY_NAME}}
                        </option>
                        <!-- <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option> -->
                    </select>
                </div>

                <!-- <div class="mt-3 d-none">
                    <label for="exampleInputEmail1" class="form-label" style="color: #908c93;">Company Name</label>
                    <select class="form-select" aria-label="Default select example"
                        style="padding: 0.375rem 2.25rem 0.375rem 0.75rem;">
                        <option selected>Open this select menu</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                    </select>
                </div> -->


                <div class="wrap-input100 validate-input mt-4" *ngIf="RegistrationType.value=='2' && !isStudent">
                    <input class="input100" formControlName="Company_Name" type="text" id="Company_Name"
                        name="Company_Name" maxlength="100">
                    <span class="focus-input100" data-placeholder="Company Name"></span>
                </div>
                <div class="container-login100-form-btn">
                    <div class="wrap-login100-form-btn">
                        <div class="login100-form-bgbtn"></div>
                        <!-- <button class="login100-form-btn" (click)="cancel()"> Cancel </button> -->
                        <button type="submit" class="login100-form-btn"> Submit </button>
                    </div>
                </div>
                <!-- <div class="text-center p-t-23"> <span class="txt1"> Don’t have an account? </span> <a class="txt2" href="#"> Sign Up </a> </div> -->
            </form>
            <div class="container-login100-form-btn">
                <span>Already have an account? <a [routerLink]="['/login']"><u>Login</u></a></span>
            </div>
        </div>
    </div>
</div>

<app-student-address *ngIf="isLoginDetailsSubmitted" (addressEvent)="getAddressDetails($event)"></app-student-address>
<app-company-registration *ngIf="isCompanyDetailsSubmitted" [Data]="pay_Load" (CompanyRegistrationEvent)="CompanyRegistrationEvent($event)" ></app-company-registration>