import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor() { 
    // let isLog=sessionStorage.getItem('isLog');
    // if(!isLog){
    //   sessionStorage.setItem('isLog',"1");
    //   location.reload()
    // }
  }

  ngOnInit(): void {
    window.scrollBy(0, 100);
  }

}
