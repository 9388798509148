import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-wellcome-registraion',
  templateUrl: './wellcome-registraion.component.html',
  styleUrls: ['./wellcome-registraion.component.css']
})
export class WellcomeRegistraionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    
  }

 

}
