<!-- <div class="modal" id="myModal_addr" data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog  modal-md">
        <div class="modal-content"> -->

<!-- Modal Header -->
<!-- <div class="modal-header py-2">
                <h4 *ngIf="!isEdit" class="modal-title"> Add Address </h4>
                <h4 *ngIf="isEdit" class="modal-title"> Edit Address</h4>
                <button type="button" (click)="close()" class="close" data-bs-dismiss="modal">&times;</button>
            </div> -->

<!-- Modal body -->
<div class="custom_container">
    <form class="well form-horizontal" [formGroup]="myForm" (ngSubmit)="onSubmit(myForm)">
        <fieldset>
            <div class="form-group row pt-4">
                <label class="col-md-4 control-label"> Address Type
                    <span class="text-danger">*</span>
                </label>
                <div class="col-md-8 inputGroupContainer">
                    <div class="input-group">
                        <select formControlName='AddressType' class="form-select form-select-sm shadow-sm rounded-lg">
                            <option value="" selected>Select Address Type</option>
                            <option *ngFor='let item of addrTypes' [value]='item.DICTIONARYNAME'>
                                {{item.DICTIONARYNAME}}</option>
                        </select>

                    </div>
                    <div *ngIf="myForm.get('AddressType').touched">
                        <span class="text-danger" *ngIf="myForm.get('AddressType').hasError('required')">
                            This field is required.
                        </span>
                    </div>

                </div>
            </div>
            <div class="form-group row pt-4">
                <label class="col-md-4 control-label"> Country
                    <span class="text-danger">*</span>
                </label>
                <div class="col-md-8 inputGroupContainer">
                    <div class="input-group">
                        <select formControlName='Country' (change)='onChangeCountry($event.target.value)'
                            class="form-select form-select-sm shadow-sm rounded-lg">
                            <option value="" selected>Select Country</option>
                            <option *ngFor='let item of addressCountry' [value]='item.DICTIONARYNAME'>
                                {{item.DICTIONARYNAME}}</option>
                        </select>

                    </div>

                </div>
            </div>
            <div class="form-group row pt-4">
                <label class="col-md-4 control-label"> State
                    <span class="text-danger">*</span>
                </label>
                <div class="col-md-8 inputGroupContainer">
                    <div class="input-group">
                        <select formControlName='State' (change)='onChangeState($event.target.value)'
                            class="form-select form-select-sm shadow-sm rounded-lg">
                            <option value="" selected>Select State</option>
                            <option *ngFor='let item of addressStates' [value]='item.DICTIONARYNAME'>
                                {{item.DICTIONARYNAME}}</option>
                        </select>

                    </div>

                </div>
            </div>
            <div class="form-group row pt-4">
                <label class="col-md-4 control-label"> City
                    <span class="text-danger">*</span>
                </label>
                <div class="col-md-8 inputGroupContainer">
                    <div class="input-group">
                        <select formControlName='City' class="form-select form-select-sm shadow-sm rounded-lg">
                            <option value="" selected>Select City</option>
                            <option *ngFor='let item of addressCity' [value]='item.DICTIONARYNAME'>
                                {{item.DICTIONARYNAME}}</option>
                        </select>

                    </div>

                </div>
            </div>
            <div class="form-group row pt-4">
                <label class="col-md-4 control-label"> Pincode
                    <!-- <span class="text-danger">*</span> -->
                </label>
                <div class="col-md-8 inputGroupContainer">
                    <div class="input-group">
                        <input type="number" min="1" type="text" formControlName='Pincode'
                            class="form-control form-control-sm shadow-sm rounded-lg" maxlength="6"
                            onkeypress="return /[0-9'-'\s]/i.test(event.key)">


                    </div>

                </div>
            </div>
            <div class="form-group row pt-4">
                <label class="col-md-4 control-label"> Permanent Address
                    <span class="text-danger">*</span>
                </label>
                <div class="col-md-8 inputGroupContainer">
                    <div class="input-group">
                        <textarea placeholder="Enter Permanent Address Details" formControlName='AddressDetails'
                            class="form-control form-control-sm shadow-sm rounded-lg">
                                    </textarea>
                    </div>

                </div>
                <div *ngIf="myForm.get('AddressDetails').touched">
                    <span class="text-danger" *ngIf="myForm.get('AddressDetails').hasError('required')">
                        This field is required.
                    </span>
                </div>
            </div>
            <div class="form-group row pt-4">
                <label class="col-md-4 control-label"> Temporary Address
                    <!-- <span  class="text-danger">*</span> -->
                </label>
                <div class="col-md-8 inputGroupContainer">
                    <div class="input-group">
                        <textarea placeholder="Enter Temporary Address" formControlName='temp_address'
                            class="form-control form-control-sm shadow-sm rounded-lg">
                                    </textarea>
                    </div>

                </div>
            </div>




        </fieldset>
    </form>

</div>
<!-- <div class="modal-footer">
                <button type="button" *ngIf="!isEdit" class="btn btn-sm btn-save" (click)="onSubmit(myForm)"
                    [disabled]="myForm.invalid">Save</button>
                <button type="button" *ngIf="isEdit" class="btn btn-sm btn-save" (click)="onSubmit(myForm)"
                    [disabled]="myForm.invalid">Update</button>
                <button type="button" class="btn btn-sm btn-danger" id="md_close_lan" (click)="close()"
                    data-bs-dismiss="modal">Close</button>
            </div> -->
<!-- <div class="text-center pt-4">
    <button type="button" class="btn btn-sm  btn-success" (click)="onSubmit(myForm)">Save</button> &nbsp;
    <button type="button" class="btn btn-sm btn-danger" (click)="close()">Close</button>
</div> -->
<!-- </div>
    </div>
</div> -->

<div class="d-none">
    <!-- <div class="text-right">
        <button type="button" class="btn btn-primary" (click)="add()" data-bs-toggle="modal"
            data-bs-target="#myModal_addr">Add</button> -->

    <!-- </div> -->
    <table *ngIf='table.length' class="table table-striped table-responsive-md btn-table table-bordered text-dark">
        <thead class="top" style="background-color: #624FD7; color:#ffffff;">
            <tr>
                <th>Address Type</th>
                <th>Country</th>
                <th>State</th>
                <th>City</th>
                <th>Pincode</th>
                <th>Address Details</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor='let item of table;let i=index'>
                <td>{{item.AddressType}}</td>
                <td>{{item.Country}}</td>
                <td>{{item.State}}</td>
                <td>{{item.City}}</td>
                <td>{{item.Pincode}}</td>
                <td>{{item.AddressDetails}}</td>
                <td style="display: flex;">
                    <span class="edit_icon" style="cursor: pointer;" (click)="editAddress(item,i)">
                        <i class="fas fa-pen pt-1 mt-1"></i></span>
                </td>
            </tr>
        </tbody>
    </table>
    <!-- <p class="text-center" *ngIf='!table.length'>No Records</p> -->
</div>