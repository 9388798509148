<section style="background: #FFCBE8;">
    <div class="container pt-4 pb-4">
        <div class="row p-4">
            <div class="col-xl-3">
                <h4 class="client-txt fw-bold">We Partner With More Than 10+ Companies</h4>
            </div>
            <div class="col-xl-9">
                <div class="owl-carousel partener11 owl-theme">
                    <div class="item" *ngFor="let partners of partenersList" >
                        <img [src]="partners.image">
                    </div>
                    <!-- <div class="item">
                        <img src="../../../../assets/img/client-logoes/logo-2.png">
                    </div>
                    <div class="item">
                        <img src="../../../../assets/img/client-logoes/logo-3.png">
                    </div>
                    <div class="item">
                        <img src="../../../../assets/img/client-logoes/logo-4.png">
                    </div>

                    <div class="item">
                        <img src="../../../../assets/img/client-logoes/logo-1.png">
                    </div>
                    <div class="item">
                        <img src="../../../../assets/img/client-logoes/logo-2.png">
                    </div>
                    <div class="item">
                        <img src="../../../../assets/img/client-logoes/logo-3.png">
                    </div>
                    <div class="item">
                        <img src="../../../../assets/img/client-logoes/logo-4.png">
                    </div> -->

                </div>
            </div>
        </div>
    </div>

</section>